.timecard-report {
  background-color: #f9f9f9;
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }

  .timecard-report {
    div.time-card-page-break {
      display: block;
      break-after: always;
      page-break-after: always;
    }

    .page-break.timecard-report-container {
      position: relative !important;
      display: block;
      float: none !important;
      clear: both !important;
      @-moz-document url-prefix() {
        min-height: calc(100vh + 15mm);
      }
    }
    .page-break.timecard-report-container:first-child {
      @-moz-document url-prefix() {
        min-height: calc(100vh - 14mm);
      }
    }
    .signatures-footer {
      border: none;
    }

    .table-inner-wrapper > div {
      height: 20px !important; // for footer spacer
    }
    .signatures-footer label {
      font-size: 9px;
    }
  }
}
