.time-card-employee-name-bar {
  width: 100%;
  .bar {
    border: 1px solid #e9e9e9;
    background-color: white;
    &.bar-height-unset {
      min-height: unset;
      height: unset;
    }
  }

  .left-container {
    display: flex;
    justify-content: flex-start;

    .employee-name-title {
      font-weight: bold;
      font-size: 24px;
    }

    .sub-title {
      font-weight: bold;
      font-size: 10px;
    }

    .sub-value {
      font-size: 10px;
    }
  }

  .right-container {
    display: flex;
    justify-content: flex-start;

    .total-label {
      font-size: 17px;
    }

    .small-icon {
      height: 32px;
    }
  }
}

@media print {
  .time-card-employee-name-bar {
    .bar {
      height: 32px !important;
      min-height: 32px !important;
    }
    .p-4 {
      padding: 0px !important;
    }
    label {
      padding-top: 2px !important;
      font-size: 9px;
    }
  }
}
