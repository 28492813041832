@import 'styles/theme.scss';

.panel-content {
  flex: 1;
  flex-wrap: nowrap; // safari fix

  &.panel-content-scroll {
    overflow-y: auto;
  }

  &.panel-content-flex {
    display: flex;
  }

  &.panel-opaque {
    background-color: $c-bg-light-gray;
  }

  > .lazy-scroll-container,
  > .lazy-loader {
    flex: 1;
  }
}
