.gusto-employee-registration-radio-group-form-field {
  .radio {
    height: auto;
  }

  .radio-label {
    width: calc(100% - 45px);
    vertical-align: top;
  }
}
