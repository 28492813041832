@import '../../styles/dashboard-styles.scss';

.dashboard-list {
  height: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;

  .list-item {
    &.dark {
      border-color: $dashboard-dark-text-subheader !important;
    }
    &.light {
      border-color: $dashboard-light-text-subheader !important;
    }
  }
}
