@import 'styles/theme.scss';

.california-break-laws-form {
  .section-title {
    margin-bottom: 6px;
    font-weight: 700;
    font-size: 20px;
  }
  .link {
    font-size: 18px;
    font-weight: 700;
  }
  .row-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #e9e9e9;
  }
  .subtitle-text {
    font-size: 14px;
    color: lighten($c-font-1, 35);
  }
}
