.equipment-cost-history-dialog {
  height: 100%;

  .dialog {
    display: flex;
  }

  .header-title {
    font-size: large;
  }

  .close-button {
    align-self: flex-start;
    margin-top: 10px;
  }

  .panel-content {
    align-items: center;

    .middle-row {
      flex: 1;
      display: -webkit-box;
      height: 100%;
    }

    .report-container {
      width: 100%;
    }
  }
}
