.company-navigation-menu {
  width: 100%;
  display: flex;
  max-height: 530px;
  overflow-y: auto;
  flex-direction: column;

  .company-navigation-menu-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .company-text {
    margin-top: 16px;
    margin-left: 16px;
    color: #78849f;
    text-transform: uppercase;
  }
}
