@import 'styles/theme.scss';

.time-off-checkboxes,
.time-off-date-pickers-container {
  display: flex;
}

.time-off-date-picker-item {
  flex-grow: 1;
}

.total-picker-item {
  width: 50px;
}

.total-time-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.total-time-picker-item {
  margin-bottom: 8px;

  &.time-field {
    width: 100px;
  }
}

.start-date-warning {
  color: $c-danger;
}
