@import 'styles/theme.scss';

.create-daily-sign-off-questions-form {
  .title-label {
    color: #31373c;
  }
  .details-label {
    color: #777a7d;
  }
  .header-title {
    font-weight: 600;
    @extend %font-labels;
    color: lighten($c-font-1, 10);
  }
}
