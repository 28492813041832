.bulk-edit-entry-form {
  .trash-row {
    .form-field {
      width: calc(100% - 44px);
    }
  }

  .undo-button {
    &:hover {
      background-color: unset;
    }
  }
}
