@import 'styles/theme.scss';

.processing-photo {
  position: relative;
  background-color: #eee;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  width: 140px;
  max-width: 140px;
  height: 140px;
  max-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .photo-icon {
    fill: RGB(178, 178, 178) !important;
    .icon {
      fill: RGB(178, 178, 178) !important;
    }
  }

  .title-label {
    color: RGB(178, 178, 178);
  }
}
