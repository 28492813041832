@import 'styles/theme.scss';

.project-cost-code-quantity {
  .quantity-field {
    width: 35%;
  }

  .date-field {
    width: 50%;
  }

  .units-label {
    font-size: 20px;
    color: $c-font-3;
    padding-bottom: 8px;
    margin-left: 10px;
  }
}
