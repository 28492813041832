@import 'styles/theme.scss';

.multi-panel {
  display: flex;

  &.stroke {
    * > {
      border-top: solid 1px $c-stroke-10;
      border-bottom: solid 1px $c-stroke-10;
      border-right: solid 1px $c-stroke-10;
    }

    border-top: solid 1px $c-stroke-10;
    border-bottom: solid 1px $c-stroke-10;
    border-left: solid 1px $c-stroke-10;
  }
}
