@import 'styles/theme.scss';

.edit-time-entry-form {
  .total-field {
    width: 20%;
  }

  .date-pickers-container {
    display: flex;
  }

  .date-picker-item {
    flex-grow: 1;
  }

  .date-pickers {
    justify-content: space-between;

    & > * {
      width: 48%;
    }

    .date-picker-form-field {
      width: 40%;
    }
  }

  .start-date-warning {
    color: $c-danger;
  }

  .conflicts-warning {
    color: $c-warning;
  }
}
