@import '~@busybusy/webapp-react-ui/dist/styles/_theme';
@import './print.scss';

// Custom theming goes here
$c-bg-light-gray: #fcfcfc;
$c-bg-light-gray-2: #fafafa;
$c-dark-blue: #17689e;
$c-mid-light-gray: #a4a9ac;
$c-mid-gray: #888888;
$c-gray-border: rgba(0, 0, 0, 0.1);
$c-page-background: #f9f9f9;
$c-align-primary: #4c84fc;
$c-align-dark-background: #20333c;
$c-align-darker-background: #101a1e;


