.expanded-time-cards-print-header:not(.standard) {
  page-break-inside: avoid;
  background-color: white;
  .organization-name {
    font-size: 8px;
    font-weight: bold;
    line-height: 1.5;
  }
  .address-information {
    font-size: 8px;
    font-weight: regular;
    line-height: 1.5;
  }

  .employee-info {
    line-height: 1.5;
    font-size: 8px;
    font-weight: regular;
  }
  .employee-info-section-title {
    font-weight: 600;
  }
  .employee-name {
    font-weight: bold;
    font-size: 10px;
  }
}

.expanded-time-cards-print-header.standard {
  page-break-inside: avoid;
  background-color: white;
  .organization-name {
    font-size: 9px;
    font-weight: bold;
    line-height: 1.5;
  }
  .address-information {
    font-size: 9px;
    font-weight: regular;
    line-height: 1.5;
  }

  .employee-info {
    line-height: 1.5;
    font-size: 9px;
    font-weight: regular;
  }
  .employee-info-section-title {
    font-weight: 600;
  }
  .employee-name {
    font-weight: bold;
    font-size: 14px;
  }
}
