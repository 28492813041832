@import 'styles/theme.scss';

.daily-sign-off-report-table {
  width: 100%;

  .center-align {
    align-items: center;
  }

  .sign-off-table-cell {
    display: flex;
    justify-content: space-between;

    &.red-text {
      color: $c-danger;
    }

    & span {
      display: block;
    }

    & .resolved {
      & > * {
        fill: #b6b6b7 !important;
      }
    }
  }

  .date-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notes-label {
    font-weight: bold;
  }

  .employee-notes {
    white-space: unset;
    word-wrap: break-word;
    text-overflow: unset;
  }

  .question-title {
    font-size: 15px;
    color: #30373d;
    font-weight: 700;
    line-height: 1.2em;
  }
  .audience-type {
    font-size: 10pt;
    font-weight: lighter;
    color: #798084;
  }

  .answer-table-cell {
    cursor: pointer;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;

    &.red-text {
      color: $c-danger;
    }

    & .resolved {
      & > * {
        fill: #b6b6b7 !important;
      }
    }
  }

  .table-header {
    position: relative !important;
    @media print {
      display: none;
    }
  }

  .table-header-spacer {
    height: 1px !important;
  }
}

.empty-sign-off-state {
  min-height: 120px;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;
  background-color: white;
  color: $c-font-2;
}
