@import 'styles/theme.scss';

.safety-reports-enable-form {
  .link {
    color: $c-primary;
    font-size: 15px;
    font-weight: 600;
  }
  .content {
    white-space: pre-line;
  }
}
