@import 'styles/theme.scss';

.cookie-consent-banner {
  text-align: center;
  background-color: $c-bg-light-gray;
  width: 100%;
  height: 75px;
  padding-left: 50px;
  padding-right: 50px;

  .btn-md {
    width: 100px;
  }
  border-bottom: solid 1px #e9e9e9;

  .ok-button {
    display: flex;
    text-align: right;
    justify-content: space-between;
    float: right;
    margin-left: auto;
    margin-right: 75px;
  }
}
