.hour-meter-details {
  .heading-label {
    color: #7a839d;
  }
  .title-label {
    color: #31373c;
  }
  .details-label {
    color: #777a7d;
  }
}
