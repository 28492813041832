@import 'styles/theme.scss';

.dashboard-clock-status {
  background: rgb(46, 160, 255);
  background: linear-gradient(0deg, rgba(46, 160, 255, 1) 0%, rgba(5, 181, 255, 1) 100%);
  border-radius: 6px;
  padding: 16px 16px 16px 24px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: none;
    background-color: #2fa1ff;
  }

  &.is-in-iframe {
    background: unset;
    background-color: $c-align-primary;
  }

  .icon {
    fill: #fff;
    height: 30px;
    width: 30px;
  }

  .dashboard-clock-status-labels {
    height: 100%;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.15;

    .dashboard-clock-status-value {
      font-size: 3em;
    }

    .dashboard-clock-status-label {
      text-transform: uppercase;
      font-size: 0.75em;
    }
  }
}
