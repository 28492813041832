@import 'styles/theme.scss';

.cellIcon {
  position: absolute;
  right: 16px;
  top: 10px;
  z-index: 1;
  fill: rgba(0, 0, 0, 0.3);
}

.showDetails {
  right: 16px;
}

.details {
  background-color: $c-bg-light-gray;
  border: 1px solid #ededed;
  border-radius: 5px;
}

.entry-description {
  white-space: pre-line;
}

.detailsContents {
  padding: 15px;
}

.column {
  width: 90%;
  max-width: 90% !important;
  min-height: 150px;
  background-color: #fff;
  & .row {
    & .col {
      width: 50%;
      & .col-header {
        display: block;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
      }
      & .col-value {
        font-size: 16px;
        word-wrap: break-word;
      }
    }
  }
}
