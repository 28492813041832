.time-card-report-employee-container {
  border-bottom: 1px solid #e9e9e9;
  .table-wrapper {
    overflow-y: hidden !important;
  }
}

.time-card-report-table {
  .date-cell {
    display: flex;
    align-items: center;
  }

  .date-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
  }

  .button {
    width: 32px;
    &.small-icon {
      height: 32px;
    }
  }

  .lock {
    display: flex;
    text-align: right;
    justify-content: space-between;
    float: right;
    margin-left: auto;
    margin-right: 0;
    .lock-button {
      fill: RGB(190, 199, 208);
      .icon {
        fill: RGB(190, 199, 208);
      }
    }
  }
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
  .time-card-report-employee-container {
    float: none !important;
    clear: both !important;
    border: none;
    margin: none;
    padding: none;
    overflow: visible !important;
  }

  .time-card-report-table {
    padding-top: 0;
    font-size: 9px;
    line-height: 1.5;
    overflow-x: visible !important;

    .table-cell {
      width: auto !important;
      padding: 3px !important;
      label {
        font-size: 9px;
        line-height: 1.5;
      }
    }
    .date-content {
      label {
        margin-left: 0 !important;
      }
    }
    .table-cell-checkbox {
      display: none !important;
    }
  }
}
