@import 'styles/theme.scss';

.daily-photo-and-notes-dashboard-card-footer {
  .footer-divider {
    background-color: $c-font-3;
  }
  .title-label {
    color: $c-font-3;
  }
}
