@import 'styles/theme.scss';

.timesheet-print-signature {
  break-inside: avoid !important;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 100px !important;
  border: 1px solid #bebebe;
  border-radius: 5px;
  background-color: white;
  .label {
    color: $c-font-1 !important;
  }
  .signature-layout {
    .label {
      color: $c-font-2 !important;
    }
    border-bottom: 1px solid #bebebe;
    align-self: flex-end;
    width: 75%;
    margin-bottom: 16px;
  }
  .signature-container {
    min-height: 52px;
    width: 100% !important;
    max-width: 94% !important;
    display: flex;
  }
  .date-layout {
    .label {
      color: $c-font-2 !important;
    }
    border-bottom: 1px solid #bebebe;
    align-self: flex-end;
    width: 25%;
    margin-bottom: 16px;
    display: flex;
  }
  .signature-image {
    max-height: 21px;
  }
  label {
    font-size: 9px !important;
  }
  .signature-image-container {
    display: flex;
  }
}
