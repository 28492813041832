@import './../../../styles/theme.scss';

.header {
  position: relative;
  flex: none;
  padding-right: $spacing-5;
  padding-left: $spacing-5;
  overflow: hidden;
  flex: none;

  &.header-stroke {
    border-bottom: 1px solid $c-stroke-10;
  }
}
