.location-history-table {
  min-width: 400px;
  max-width: 10%;
  height: 80vh;
  .location-bar {
    border-bottom: solid 2px rgb(246 223 223);
  }
  .location-picker {
    justify-content: flex-start;
  }
  .location-header-container {
    display: inline;
  }
  .location-header {
    font-weight: bold;
    float: left;
  }
  .location-distance {
    float: right;
    &.unauthorized {
      color: red;
    }
  }
  .location-details {
    clear: both;
  }
  .highlighted {
    background-color: rgba(0, 0, 0, 0.03);
    .location-header {
      color: #4f98f7;
    }
  }
  .table-cell {
    padding: 0;
  }
  .location-history-cell {
    > div {
      padding: 20px;
      line-height: 0.5em;
    }
  }
}
