@import 'styles/theme.scss';

.activity-details-dialog {
  .filter-bar {
    background-color: white;
    border-bottom: 1px solid $c-stroke-field;
    .type-selector {
      min-width: 220px;
    }
  }

  @media print {
    .printer-head {
      border-bottom: solid 1px $c-stroke-10 !important;
    }

    .row {
      overflow-y: hidden;
    }

    .dialog {
      box-shadow: none;
    }

    @each $object-type in 'employee', 'cost-code', 'equipment', 'date' {
      &.time-entry-report-filtered-by-#{$object-type} {
        .time-entry-data-table-#{$object-type}-column {
          display: none !important;
        }
      }
    }
  }
}
