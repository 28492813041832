@import 'styles/theme.scss';

.spinner {
  display: inline-block;
  position: relative;

  &.spinner-sm {
    &,
    & > div {
      width: 40px;
      height: 40px;
    }
  }

  &.spinner-x-sm {
    &,
    & > div {
      width: 24px;
      height: 24px;
    }
  }

  &.spinner-md {
    &,
    & > div {
      width: 72px;
      height: 72px;
    }
  }

  div {
    display: block;
    position: absolute;
    margin: 4px;
    border: 4px solid #eee;
    border-radius: 50%;
    border-color: $c-font-3 transparent transparent;
    box-sizing: border-box;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  &.dark {
    & > div {
      border-color: $c-font-3 transparent transparent;
    }
  }

  &.light {
    & > div {
      border-color: #fff transparent transparent;
    }
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
