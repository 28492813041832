.manage-equipment {
  @media print {
    h2 {
      padding-top: 20px;
    }
  }

  .small-icon {
    height: 32px;
  }
}

.management-lists-search.text-field {
  input {
    min-width: 19rem;
  }
}
