.activity-report-filter {
  .panel-content.panel-content-scroll {
    overflow-y: unset !important;
  }
  .by-date-range-limit {
    width: 72px;
    .field-counter {
      visibility: hidden;
      height: 0px;
    }
  }
}
