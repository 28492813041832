@import 'styles/theme.scss';

.time-entry-gps-icon {
  &.button {
    width: 32px;
    &.small-icon {
      height: 32px;
    }

    &.gps-icon-disabled {
      fill: $c-danger !important;
      .icon {
        fill: $c-danger !important;
      }
    }

    &.gps-icon-warning {
      fill: $c-warning !important;
      .icon {
        fill: $c-warning !important;
      }
    }
  }
}
