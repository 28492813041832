@import '../../../styles/theme.scss';

.photo-fullscreen-viewer {
  .btn {
    &.btn-dark {
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .panel-content {
    .middle-row {
      background-color: #fcfcfc;
      flex: 1;
    }
  }

  .header {
    flex-shrink: 0;
    background-color: #fff;
    // color: $c-font-light-1;
  }

  .footer {
    flex-shrink: 0;
    height: 100px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;

    .row {
      position: relative;

      .photo-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .annotated-button-tray {
        .btn-primary {
          width: 110px;
        }

        .btn-secondary {
          width: 110px;
        }
      }
    }
  }

  .side-panel {
    max-width: 400px;
    .comment-panel {
      flex-grow: 1;
    }
  }
}
