@import '../../../../../components/foundation/dashboard/styles/dashboard-styles.scss';

.dashboard-error-card {
  height: 100%;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .clickable {
    cursor: pointer;
  }

  .dashboard-enable-title-card {
    margin: 12px 0 24px;
  }

  &.dark {
    .dashboard-enable-title-card {
      color: $dashboard-dark-text-header;
    }

    .icon {
      fill: $dashboard-dark-text-header;
    }
  }

  &.light {
    .dashboard-enable-title-card {
      color: $dashboard-light-text-header;
    }
    .icon {
      fill: $dashboard-light-text-header;
    }
  }

  .icon-container {
    min-width: 60px;
    max-width: 60px;
  }
}
