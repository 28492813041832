@import 'styles/theme.scss';

.break-form {
  width: 100%;

  .total-time-field {
    // Matches the time field width
    input {
      width: 125px;
    }
  }

  .buttons-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: 24px;

    .btn {
      width: 90px;

      &:first-child {
        margin-right: 12px;
      }

      &:last-child {
        border: 0;
      }
    }
  }

  &-member-day {
    .field-footer-error {
      height: auto;

      &.field-footer-warning-list {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        margin: 0;
        padding: 0;
        color: $c-danger;
        list-style: none;

        li {
          display: inline;
          margin: 4px 0 0;
          padding: 0;
          list-style: none;
        }
      }
    }
  }
}

.break-form-conflict-confirmation {
  .break-conflict-member-list {
    margin: 0 0 1em;
    padding: 0;
    list-style: none;

    li {
      display: inline-flex;
      margin: 0 0.5em 0 0;
      padding: 0;

      .member-name {
        display: inline-block;
        max-width: 15em;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
      }

      &:after {
        content: ',';
      }

      &:last-child {
        &:after {
          content: '';
        }
      }
    }
  }
}
