@import 'styles/theme.scss';

.badge {
  display: inline-flex;
  padding: 0px 10px;
  border-radius: 50px;
  color: #fff;
  justify-content: center;
  align-content: center;

  &.badge-primary {
    background-color: $c-primary;
  }

  &.badge-gray {
    background-color: $c-mid-light-gray;
  }

  &.badge-dark-blue {
    background-color: $c-dark-blue;
  }
}
