.confirmation-dialog {
  .content {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    margin-right: 32px;

    .tray-right {
      align-content: flex-end;
      justify-content: flex-end;
      padding-bottom: 20px;
    }
  }
}
