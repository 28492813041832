@import 'styles/theme.scss';

.weekly-grid-container {
  .weekly-grid-report {
    .employee-cell {
      display: flex;
      align-items: center;
      min-width: 350px;
    }
    .table-head {
      padding: 0 !important;
      &.employee-header {
        padding: 8px 16px !important;
      }
    }
    .date-cell {
      position: relative;
      &:nth-child(odd) {
        background: rgba($c-primary, 0.05);
      }

      & .date-cell-table {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
    .date-cell-row {
      display: flex;
      justify-content: space-between;
      height: 100%;
      padding: 0;
      margin: 0;
      .date-cell-td {
        border-right: solid 1px rgba(0, 0, 0, 0.1);
        min-width: 75px;
        max-width: 75px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        align-items: center;
        display: flex;
        justify-content: space-around;
        &:last-of-type {
          border: none;
        }
      }
      &.header {
        font-size: 12px;
        border-top: solid 1px rgba(0, 0, 0, 0.1);
        font-weight: bold;
        & .date-cell-td {
          height: unset;
        }
      }
    }
  }
}
