.time-entry-split-dialog {
  .split-dialog-container {
    .member-name {
      font-size: 14px;
    }
    .time-entry-range {
      display: block;
      font-size: 12px;
      & > div {
        display: inline-block;
      }
    }
  }
}
