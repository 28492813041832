@import '../styles/dashboard-styles.scss';

.dashboard-card-header-divider {
  &.dark {
    border-bottom: solid 1px rgba(255, 255, 255, 0.05);
  }

  &.light {
    border-bottom: solid 1px #dddedf;
  }
  padding-bottom: 8px;
}

.dashboard-card-header {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;

  .dashboard-card-header-left-content {
    line-height: 1.25;

    &.without-header {
      align-self: center;
    }

    .dashboard-card-header-left-header {
      &:not(.font-small) {
        font-weight: 600;
        font-size: 1.75em;
      }

      &.font-small {
        font-weight: 700;
        font-size: 0.75em;
      }
    }

    .dashboard-card-header-left-subheader {
      text-transform: uppercase;
      font-size: 0.75em;
      font-weight: 600;
    }
  }

  .dashboard-card-header-right-content {
    > div {
      text-decoration: underline;
      font-size: 0.75em;
      font-weight: 600;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.dark {
    .dashboard-card-header-left-content {
      .dashboard-card-header-left-header {
        color: $dashboard-dark-text-header;
      }

      .dashboard-card-header-left-subheader {
        color: $dashboard-dark-text-subheader;
      }
    }

    .dashboard-card-header-right-content {
      color: $dashboard-dark-text-subheader;
    }
  }

  &.light {
    .dashboard-card-header-left-content {
      .dashboard-card-header-left-header {
        color: $dashboard-light-text-header;
      }

      .dashboard-card-header-left-subheader {
        color: $dashboard-light-text-subheader;
      }
    }

    .dashboard-card-header-right-content {
      color: $dashboard-light-text-subheader;
    }
  }
}
