@import 'styles/theme.scss';

.media-entry-thumbnail-tray {
  .recent-photo-content {
    min-height: 250px;
  }

  .empty-state {
    min-height: 275px;
    padding-top: 100px;
  }

  .view-all-cell {
    background-color: $c-font-3;
    color: white;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 140px;
    height: 140px;
    min-height: 140px;
    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}
