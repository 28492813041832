@import 'styles/theme.scss';
@import '../../../../components/domain/project/ProjectTree/ProjectLeaf/ProjectLeaf.scss';

.project-activity {
  width: 100%;

  .project-activity-table {
    border: solid 1px $c-stroke-10 !important;
    .project-name {
      font-weight: 200;
      margin: 0;
    }
    .table-body {
      height: 100% !important;
      .title-cell,
      .report-cell {
        cursor: pointer;
      }
      .title-cell {
        & > div {
          padding: 0 20px;
          line-height: 26px;
        }
      }
      .report-cell {
        padding: 0 20px;
      }
      .level1Background {
        background: white;
        &:hover {
          background: #eaecee;
        }
      }
      .level1SummaryBackground {
        background: #ebf5ff;
        &:hover {
          background: #d6e1eb;
        }
      }
      .level2Background {
        background: #f4f5f6;
        &:hover {
          background: #e0e3e6;
        }
        & .title-cell {
          padding-left: 20px;
        }
      }
      .level2SummaryBackground {
        background: #e2ecf7;
        &:hover {
          background: #d0d8e2;
        }
        & .title-cell {
          padding-left: 20px;
        }
      }
      .level3Background {
        background: #eaecee;
        &:hover {
          background: #d7dbde;
        }
        & .title-cell {
          padding-left: 40px;
        }
      }
      .level3SummaryBackground {
        background: #d9e4f0;
        &:hover {
          background: #ccd6e1;
        }
        & .title-cell {
          padding-left: 40px;
        }
      }
      .level4Background {
        background: #e0e3e6;
        &:hover {
          background: #cfd3d7;
        }
        & .title-cell {
          padding-left: 60px;
        }
      }
      .level4SummaryBackground {
        background: #d0dbe8;
        &:hover {
          background: #b6c3d2;
        }
        & .title-cell {
          padding-left: 60px;
        }
      }
      .level5Background {
        background: #d7dbde;
        &:hover {
          background: #c7cbd0;
        }
        & .title-cell {
          padding-left: 80px;
        }
      }
      .level5SummaryBackground {
        background: #c7d4e1;
        &:hover {
          background: #abbac9;
        }
        & .title-cell {
          padding-left: 80px;
        }
      }
      .level6Background {
        background: #cfd3d7;
        &:hover {
          background: #b2b6b9;
        }
        & .title-cell {
          padding-left: 100px;
        }
      }
      .level6SummaryBackground {
        background: #c0cddb;
        &:hover {
          background: #afbdcd;
        }
        & .title-cell {
          padding-left: 100px;
        }
      }
      .level7Background {
        background: #c7cbd0;
        &:hover {
          background: #a9adb1;
        }
        & .title-cell {
          padding-left: 120px;
        }
      }
      .level7SummaryBackground {
        background: #b9c6d4;
        &:hover {
          background: #a5b4c3;
        }
        & .title-cell {
          padding-left: 120px;
        }
      }

      .project-leaf-btn {
        width: 26px;
        height: 26px;
        padding: 0;
        z-index: 100;
        background: #fff;
      }
    }
  }

  @media print {
    .title-cell,
    .report-cell {
      vertical-align: middle !important;
      text-align: center;
      & > div {
        padding: 0px !important;
        font-size: 8px;
      }
      padding: 0px 3px !important;
      font-size: 8px;
    }
  }
}
