@import 'styles/theme.scss';

.clock-in-form {
  .submission-required-well {
    white-space: pre-line;
  }

  .validation-banner-title {
    color: $c-danger !important;
  }

  .warning-banner-title {
    color: $c-primary !important;
  }

  .start-date-warning {
    color: $c-danger;
  }

  .conflicts-warning {
    color: $c-warning;
  }

  .total-field {
    width: 20%;
  }

  .sign-in-check-button {
    border: 1px solid #e6e7e7;
    border-radius: 5px;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    &.incomplete-item {
      cursor: pointer;
      color: #a9aeb1;

      .covid-chevron {
        & > path {
          fill: #c2c5c7;

          &:last-of-type {
            fill: none;
          }
        }
      }
    }

    &.complete-item {
      color: #4b5764;
      background: #f5f5f5;
      cursor: not-allowed;

      .covid-chevron {
        & > path {
          fill: #c0c3c5;

          &:last-of-type {
            fill: none;
          }
        }
      }
    }
  }
}

.clocked-in-dialog {
  white-space: pre-line;
}

.date-pickers-container {
  display: flex;
}

.date-picker-item {
  flex-grow: 1;
}

.date-pickers {
  justify-content: space-between;

  & > * {
    width: 48%;
  }

  .date-picker-form-field {
    width: 40%;
  }
}
