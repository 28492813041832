.timesheets-side-panel {
  width: 100%;

  .time-range {
    justify-content: flex-start;
    flex-grow: 1;

    .time-range-end-time,
    .time-range-start-time {
      flex-grow: 1;
    }
  }

  .timesheets-side-panel-settings {
    > * {
      margin-bottom: 18px;
    }
  }

  .timesheets-side-panel-filter {
    > * {
      margin-bottom: 18px;
    }
  }

  .more-filters {
    color: #3598fe;
    font-weight: 600;

    &:hover {
      cursor: pointer;
    }
  }
}
