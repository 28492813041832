@import './../../../../styles/theme.scss';

.min-navigation {
  max-width: 80px;
  min-width: 80px;
  background-color: #2e3e51;
  color: rgba(255, 255, 255, 0.6);
  fill: rgba(255, 255, 255, 0.6);

  &.is-in-iframe {
    min-width: 64px;
    max-width: 64px;

    background-color: $c-align-dark-background;

    .nav-item {
      &.active {
        background-color: darken($c-align-primary, 3);
      }
    }
  }

  .cls-1 {
    fill: #caccd0;
  }

  // background-image: linear-gradient(#463e52, #2d3e50); //#3a3e50,
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  z-index: 901;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  .side-nav-menu-button {
    background: #697480;

    &:hover {
      background-color: #596574;
    }
  }

  .btn-label {
    color: rgba(255, 255, 255, 0.6);
  }

  h3 {
    align-self: flex-start;
    margin-left: 24px;
  }

  .icon {
    fill: #caccd0;
  }

  .person-icon {
    background: #697480;
    border-radius: 50%;
  }

  .divider {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .min-nav-menu {
    .nav-item {
      width: 100%;
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 4px 0;
  }

  .nav-item {
    width: 100%;
    margin: 4px 8px 0px;

    &:hover {
      cursor: pointer;
    }

    &.active-parent {
      background-color: #425062;
    }

    display: flex;
    justify-content: center;
    justify-self: center;
    height: 50px;
    align-items: center;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.8);

    &:hover:not(.active) {
      background-color: rgba(0, 0, 0, 0.15);
    }

    &.active {
      background-color: darken($c-primary, 3);

      .icon {
        fill: rgba(255, 255, 255, 0.8);
      }

      .btn-label {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  .button-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 8px;

    .divider {
      margin: 8px 0;
    }

    .btn {
      justify-content: center;
    }
  }
}

.min-navigation::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
