@import 'styles/theme.scss';

.embedded-linked-text {
  white-space: pre-line;
  a,
  a:visited,
  a:hover,
  a:focus,
  a:hover,
  a:active {
    text-decoration: underline;
    color: $c-primary;
  }
}
