@import 'styles/theme.scss';

.timesheets-column-form {
  .badge {
    font-size: 12px;
    font-weight: bold;
  }

  .column-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .primary-text {
      margin-bottom: 6px;
      font-weight: 600;
      @extend %font-labels;
      color: lighten($c-font-1, 10);
    }

    .secondary-text {
      font-size: 12px;
      color: lighten($c-font-1, 40);
    }
  }
  .weekly-grid-table {
    display: table;
    .weekly-grid {
      display: table-row;
      .weekly-grid-label {
        display: table-cell;
        width: 250px;
      }
      .weekly-grid-checkbox {
        display: table-cell;
        width: 150px;
        justify-content: center;
      }
    }
  }
}
