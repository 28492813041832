@import '../styles/dashboard-styles.scss';
@import 'styles/theme.scss';

.dashboard-card {
  min-width: 300px;
  min-height: 500px;
  border-radius: 6px;
  position: relative;

  &.project-employee {
    min-height: unset;
  }

  .blurred-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background-image: url('../../../../assets/images/blurred_dashboard_bg.png');
    background-repeat: no-repeat;
    background-position: 50%;
    background-clip: content-box;
    overflow: hidden;
    opacity: 0.6;
  }

  &.dark {
    background-color: $dashboard-dark-background;

    &.is-in-iframe {
      background-color: $c-align-dark-background;
    }

    color: $dashboard-dark-text;
    .icon {
      fill: $dashboard-dark-text-header;
    }
  }

  &.light {
    color: $dashboard-light-text;
    background-color: $dashboard-light-background;
    border: $dashboard-light-border;
    .icon {
      fill: $dashboard-light-text-header;
    }
  }
}

.default-padding {
  padding: 18px 30px;
}

.divider-padding {
  padding: 18px 0px;

  > * {
    padding: 0px 30px;
  }
}
