@import 'styles/theme.scss';

.command-pallet {
  .search-bar {
    width: 100%;
  }
  .command-pallet-row:focus {
    border: solid 0px $c-primary;
    outline: none;
    box-shadow: $c-shadow-field-focus;
  }

  .command-pallet-row-item {
    word-wrap: break-word;
  }

  .header {
    font-weight: bold;
    font-size: 20px;
  }

  .icon {
    fill: RGB(190, 199, 208);
  }
}
