@import 'styles/theme.scss';

.action-header {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  left: 0;
  transform: translateY(-100%);
  transition: transform 250ms, opacity 250ms;
  background-color: $c-font-2;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  z-index: 1;

  &.action-header-visible {
    transform: translateY(0);
    opacity: 1;
    z-index: 100;
  }
}
