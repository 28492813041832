@import 'styles/theme.scss';

.time-range-form-field {
  > .form-field {
    padding-bottom: 0;
  }

  .time-field {
    min-width: 100px;
  }

  .dash {
    justify-self: center;
    padding: 10px 4px;
  }

  .time-zone-sup {
    font-size: 10px;
    color: $c-font-2;
    text-align: end;
    margin-right: 12px;
  }
}
