.picker-list {
  &:focus {
    outline: none;
  }

  .picker-header {
    height: 70px;
  }

  .picker-sheet-list {
    max-height: 300px;
  }

  & .highlight {
    font-weight: 700;
    color: #fff;
  }
}
