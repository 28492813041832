.employee-summary-table {
  justify-content: center;
  overflow-x: auto;
  overflow-y: unset !important;

  .employee-cell {
    display: flex;
    align-items: center;

    label {
      margin-bottom: 0px;
    }

    .button {
      width: 32px;

      &.small-icon {
        height: 32px;
      }
    }
  }

  .table-wrapper {
    overflow: visible;
  }


  .employee-table-cell {
    border-left: none !important;
  }

  .wrapping-table-cell {
    overflow-x: inherit;
    white-space: inherit;
  }
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }

  .employee-summary-table {
    .table-cell {
      width: auto !important;
      padding: 3px !important;
      font-size: 9px;
      line-height: 1;

      * {
        font-size: 9px;
        line-height: 1;
      }
    }
    .table-cell:not(.employee-table-cell) {
      width: auto !important;
    }
    .employee-table-cell {
      label {
        margin-left: 5px !important;
      }
    }

    .table-cell-checkbox {
      display: none !important;
    }
  }
}
