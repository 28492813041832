@import 'styles/theme.scss';

.project-picker {
  .picker-row-title {
    flex: 1;
    padding-right: 10px;
  }
}

.picker-menu {
  .subproject-btn {
    width: 48px;
    padding-left: 8px;
    border-left: 1px solid $c-stroke-10;
  }

  .focused {
    .subproject-btn {
      border-left: 1px solid rgba(255, 255, 255, 0.4);

      & .btn:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      .icon {
        fill: rgba(255, 255, 255, 0.9);
      }
    }

    .f-sm {
      &.fc-2 {
        color: #fff !important;
      }
    }
  }
}
