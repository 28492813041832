@import 'styles/theme.scss';

.schedule-equipment-hourly-cost-change {
  .schedule-equipment-hourly-change-header {
    display: flex;
    justify-content: space-between;
  }

  .schedule-equipment-hourly-change-title {
    font-size: large;
  }

  .schedule-equipment-hourly-change-item-title {
    margin-bottom: 1px;
    color: $c-font-2;
    font-size: 15px;
  }

  .schedule-equipment-hourly-change-item-value {
    color: $c-font-2;
    font-size: 16px;
    margin-right: 20px;
  }
}
