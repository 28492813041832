@import 'styles/theme.scss';
.lock-date-dialog {
  height: 100%;

  .dialog {
    display: flex;
  }

  .tray-right {
    justify-content: flex-end;
    padding: 10px 10px 20px 0px;
  }

  .content {
    padding: 20px 32px;
  }
}
