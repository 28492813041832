.csv-import-equipment-review-table {
  .success-icon {
    // Fixes weird table image padding problem with checkbox
    padding: 8px 0px 0px;
  }

  @media print {
    .firstName {
      padding: 3px 5px !important; // Add x padding for icon on left
    }
  }
}
