.expanded-tme-cards-contents {
  max-width: 1300px;
  min-width: 1300px;
  width: 1300px;
  background-color: white;
  .section-title {
    padding: 20px 20px 0px 20px;
    color: #7e839d;
    font-weight: bold;
  }
  .signature-disclaimer {
    padding: 0px 0px 10px 20px;
    font-size: 12px;
  }
  .signatures-footer {
    border: 0px;
    padding-bottom: 30px !important;
  }
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
  .expanded-tme-cards-contents:not(.standard) {
    float: none !important;
    clear: both !important;
    border: none;
    margin: none;
    padding: none;
    overflow: visible !important;
    max-width: unset !important;
    min-width: unset !important;
    width: 100% !important;

    .signature-disclaimer {
      font-size: 8px !important;
      line-height: 1.5;
    }
    .summary-item {
      page-break-inside: avoid;
      padding: 0px 0px 0px 0px;

      .section-title {
        font-size: 10px !important;
        padding: 0px 0px 0px 20px;
      }
    }
  }

  .expanded-time-cards-time-entries-table:not(.standard) {
    line-height: 1.5;
    overflow-x: visible !important;
    padding: 0px 20px 0px 20px !important;

    & .table-head {
      padding: 0px 14px 0px 14px;
      height: 10px !important;
    }

    .printer-head {
      .table-cell {
        width: auto !important;
      }

      .expanded-activity-report-header {
        &.table-cell {
          width: 35% !important;
        }
      }
    }

    .expanded-activity-report-row {
      .table-cell {
        width: 35% !important;
      }
    }

    .activity-row {
      font-size: 8px !important;
      padding: 0px !important;
      margin: 0px 0px 0px 7px !important;
    }

    .table-cell {
      font-size: 8px !important;
      width: auto !important;
      label {
        font-size: 8px;
        line-height: 1.5;
      }
      .p-2 {
        padding: 0px 10px 0px 7px !important;
      }
      .total-date-row {
        height: 10px;
        font-size: 8px !important;
        .p-2 {
          padding: 0px 10px 0px 0px !important;
        }
      }
      .date-row {
        height: 10px;
        font-size: 8px !important;
        line-height: 1;
      }
      .time-off-type {
        font-size: 8px !important;
      }
    }
    .date-content {
      label {
        margin-left: 0 !important;
      }
    }
    .table-cell-checkbox {
      display: none !important;
    }
  }

  .expanded-time-cards-summary-table:not(.standard) {
    padding: 0px 20px 0px 20px !important;
    line-height: 1.5;
    overflow-x: visible !important;

    & .table-head {
      padding: 0px 14px 0px 14px;
      height: 10px !important;
    }

    .table-cell {
      font-size: 8px !important;
      width: auto !important;
      .p-2 {
        padding: 0px 12px 0px 6px !important;
      }
      label {
        font-size: 8px;
        line-height: 1.5;
      }
    }
    .date-content {
      label {
        margin-left: 0 !important;
      }
    }
    .table-cell-checkbox {
      display: none !important;
    }
  }

  .expanded-tme-cards-contents.standard {
    float: none !important;
    clear: both !important;
    border: none;
    margin: none;
    padding: none;
    overflow: visible !important;
    max-width: unset !important;
    min-width: unset !important;
    width: 100% !important;

    .section-title {
      font-size: 12px !important;
    }

    .signature-disclaimer {
      font-size: 9px !important;
    }
    .summary-item {
      page-break-inside: avoid;
    }
  }

  .expanded-time-cards-time-entries-table.standard {
    padding-top: 0;
    line-height: 1.5;
    overflow-x: visible !important;

    & .table-head {
      padding: 0px 14px 0px 14px;
      height: 20px !important;
    }

    .printer-head {
      .table-cell {
        width: auto !important;
      }

      .expanded-activity-report-header {
        &.table-cell {
          width: 35% !important;
        }
      }
    }

    .expanded-activity-report-row {
      .table-cell {
        width: 35% !important;
      }
    }

    .table-cell {
      font-size: 9px !important;
      width: auto !important;
      label {
        font-size: 9px;
        line-height: 1.5;
      }
      .total-date-row {
        font-size: 9px !important;
        height: 20px !important;
        .p-2 {
          padding: 4px 8px 0px 0px !important;
        }
      }
      .date-row {
        height: 20px !important;
        font-size: 9px !important;
      }
      .time-off-type {
        font-size: 9px !important;
      }
    }
    .date-content {
      label {
        margin-left: 0 !important;
      }
    }
    .table-cell-checkbox {
      display: none !important;
    }
  }

  .expanded-time-cards-summary-table.standard {
    padding-top: 0;
    line-height: 1.5;
    overflow-x: visible !important;

    & .table-head {
      padding: 0px 14px 0px 14px;
      height: 20px !important;
    }

    .table-cell {
      font-size: 9px !important;
      width: auto !important;
      label {
        font-size: 9px;
        line-height: 1.5;
      }
    }
    .date-content {
      label {
        margin-left: 0 !important;
      }
    }
    .table-cell-checkbox {
      display: none !important;
    }
  }
}
