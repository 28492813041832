.thumbnail-tray {
  display: flex;
  flex-flow: row wrap;
  margin-right: -8px;

  > div {
    margin-top: 8px;
    margin-right: 8px;
  }
}
