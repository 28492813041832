.expanded-time-cards-time-entries-table {
  padding: 20px;
  background-color: white;
  .table-body {
    .table-row {
      .table-cell {
        vertical-align: top;
        border-left-width: 0;
      }
    }
  }
  .activity-row {
    line-height: 1.2;
  }
  .activity-title {
    min-width: 70px;
    max-width: 70px;
    width: 70px;
  }
  .wrap-text {
    white-space: unset;
    word-wrap: break-word;
    text-overflow: unset;
    overflow-x: unset;
    overflow-y: unset;
    white-space: pre-line;
  }
  .wrapping-table-cell {
    white-space: unset;
    word-wrap: break-word;
    text-overflow: unset;
    overflow-x: inherit;
    overflow-y: auto;
    white-space: pre-line;
  }
  .table-view-header-no-border {
    border-left: none !important;
    border-bottom: none !important;
    font-size: 12px;
    font-weight: bold;
  }
  .table-view-cell-no-border {
    border: 0px !important;
    border-bottom: 1px solid #dee8ec !important;
    padding: 0 !important;
    margin: 0 !important;
    border-collapse: collapse;
    font-size: 12px;
    font-weight: regular;
  }
  .table-header {
    border-bottom: none !important;
  }
  .table-footer {
    border-top: none !important;
  }
  .date-row {
    font-size: 12px;
    font-weight: regular;
    height: 40px;
    background-color: #dee8ec !important;
    display: flex;
    align-items: center;
    .lock-button {
      fill: RGB(190, 199, 208);
      .icon {
        fill: RGB(190, 199, 208);
      }
    }
    .label {
      cursor: pointer;
    }
  }
  .total-date-row {
    font-size: 12px;
    font-weight: regular;
    height: 40px;
    background-color: #dee8ec !important;
    .label {
      cursor: pointer;
    }
  }
  .time-off-type {
    font-size: 12px;
    font-weight: bold;
  }
  .tab {
    margin-right: 10px;
  }
}
