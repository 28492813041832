@import 'styles/theme.scss';

.file-uploader {
  .file-uploader-upload-section {
    border: 2px dashed #ccc;
    border-radius: 3px;
  }

  &.file-uploader-error {
    border: 2px dashed $c-danger;
  }

  .file-uploader-divider {
    border-top: 2px dashed #ccc;
  }

  .dropzone {
    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      margin: 0;

      span {
        color: $c-primary;
      }
    }
  }
}
