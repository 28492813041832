@import 'styles/theme.scss';
@import '../styles/dashboard-styles.scss';

.dashboard-ribbon {
  width: 100%;
  border-left: #7559ff 5px solid;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  &.clickable {
    &:hover {
      cursor: pointer;

      &.dark {
        background-color: #27343f;
      }

      &.light {
        background-color: #f7f7f7;
      }
    }
  }

  &.dark {
    background-color: $dashboard-dark-background;
    color: $dashboard-dark-text-header;

    &.is-in-iframe {
      background-color: $c-align-dark-background;
    }
  }

  &.light {
    padding: $spacing-4;
    border-color: $c-stroke-10;
    background-color: $dashboard-light-background;
    color: $dashboard-light-text-header;
  }
}
