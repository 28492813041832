@import 'styles/theme.scss';

.time-entry-data-table {
  width: 100%;
  .table-row {
    min-height: 76px;
    max-height: 200px;
  }

  .wrapping-table-cell {
    overflow-x: inherit;
    white-space: inherit;
  }

  .description-note {
    white-space: unset;
    word-wrap: break-word;
    text-overflow: unset;
    overflow-x: inherit;
    max-height: 200px;
    overflow-y: auto;
    display: flex;
    white-space: pre-line;
  }

  .truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .date-cell {
    display: flex;
    align-items: center;
    .right-content {
      display: flex;
      text-align: right;
      justify-content: space-between;
      float: right;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button {
    width: 32px;
    &.small-icon {
      height: 32px;
    }
  }

  .menu-date {
    display: flex;
    align-items: center;
  }

  .empty-entry-state {
    min-height: 120px;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    background-color: white;
    color: $c-font-2;
  }

  .table-cell-checkbox {
    .checkbox {
      margin-left: 15px;
    }
  }

  .table-view-cell-no-left-border {
    border-left: none !important;
  }
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }

  .time-entry-data-table {
    .table-wrapper {
      padding-bottom: 0px !important;
    }

    padding-top: 0;
    font-size: 9px !important;
    overflow: unset !important;

    .wrapping-table-cell {
      .label {
        line-height: 1.25 !important;
      }
    }

    .table-row {
      box-decoration-break: clone;
    }

    .table-cell {
      text-align: left !important;
      padding: 5px !important;

      label {
        font-size: 9px !important;
      }
    }

    td {
      font-weight: bold;
      background-color: white !important;
    }

    td,
    tr {
      width: auto !important;
    }

    .time-entry-data-table-date-column {
      width: 8% !important;
    }

    .time-entry-data-table-employee-column {
      width: 10% !important;
    }

    .time-entry-data-table-start-column {
      width: 8% !important;
    }

    .time-entry-data-table-end-column {
      width: 8% !important;
    }

    .time-entry-data-table-project-column {
      width: 12% !important;
    }

    .time-entry-data-table-cost-code-column {
      width: 8% !important;
    }

    .time-entry-data-table-equipment-column {
      width: 8% !important;
    }

    .time-entry-data-table-description-column {
      width: 17% !important;
      line-height: 1.1 !important;
    }

    .time-entry-data-table-breaks-column {
      width: 5% !important;
    }

    .time-entry-data-table-total-column {
      width: 5% !important;
    }

    .time-entry-data-table-type-column {
      width: 4% !important;
    }

    .menu-date {
      label {
        margin-left: 0 !important;
      }
    }
    .table-cell-checkbox {
      display: none !important;
    }
    .description-note {
      max-height: 100%;
      overflow-y: unset !important;
    }

    .table-cell {
      border-left: none !important;
    }
  }
}
