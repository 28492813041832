@import 'styles/theme.scss';

.equipment-activity-report-details {
  width: 100%;

  .equipment-activity-report-details-table {
    border: solid 1px $c-stroke-10 !important;

    .equipment {
      font-size: 16px;
      font-weight: 200;
    }
    .equipment-make-model {
      font-size: 12px;
      opacity: 0.8;
    }

    @media print {
      .equipment {
        font-size: 12px;
      }
      .equipment-make-model {
        font-size: 9px;
      }
    }
  }
}
