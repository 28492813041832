@import 'styles/theme.scss';

.details-card {
  & + .details-card {
    h3 {
      margin-top: 36px;
    }
  }

  h3 {
    font-size: 15px;
  }
}
