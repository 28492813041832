@import 'styles/theme.scss';

.quick-search-employee-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .status-title {
    font-weight: bold;
    font-size: 20px;
  }
  .status-content {
    justify-content: center;
  }
  .icon {
    fill: RGB(190, 199, 208);
  }
}

.clocked-in-circle {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #5d9bec;
}

.clocked-out-circle {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #c9ccd0;
}

.on-break-circle {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #f9be3d;
}

.timer-status-left-content {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.capsule-button {
  width: 100px;
}
