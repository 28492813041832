@import '@busybusy/webapp-react-ui/dist/styles/index.scss';

.csv-import-content {
  text-align: center;

  .error-list {
    text-align: left;
  }
  .spaced-buttons {
    display: inline-grid;
    margin: 0 auto;
    grid-auto-columns: 1fr;

    .btn {
      margin: $spacing-8 $spacing-1;
      grid-row: 1;
    }
  }

  p {
    margin: 0;
  }

  & > p {
    font-style: italic;
  }

  .grouped-text {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  svg {
    padding: 24px;
    display: block;
    margin: 0 auto;
  }
}
