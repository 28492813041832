@import './animation-classes.scss';
@import './print.scss';
@import './util.scss';

input {
  font-family: 'Nunito Sans', sans-serif;
}

a,
a:visited,
a:hover,
a:focus,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}
