@import 'styles/theme.scss';

.switch-at-form {
  .total-field {
    width: 20%;
  }

  .date-pickers-container {
    display: flex;
  }

  .date-picker-item {
    flex-grow: 1;
  }

  .date-pickers {
    justify-content: space-between;

    & > * {
      width: 48%;
    }

    .date-picker-form-field {
      width: 40%;
    }
  }

  .start-date-warning {
    color: $c-danger;
  }

  .conflicts-warning {
    color: $c-warning;
  }

  .trash-row {
    .form-field {
      width: calc(100% - 44px);
    }
  }

  .undo-button {
    &:hover {
      background-color: unset;
    }
  }
}
