@import 'styles/theme.scss';

.typography {
  &.primary {
    color: $c-primary;
    &:hover {
      &.clickable {
        color: darken($c-primary, 20);
      }
    }
  }
  &.tertiary {
    color: $c-tertiary;
    &:hover {
      &.clickable {
        color: darken($c-tertiary, 10);
      }
    }
  }
  &.error {
    color: $c-danger;
    &:hover {
      &.clickable {
        color: darken($c-danger, 15);
      }
    }
  }
  &.medium-gray {
    color: $c-font-2;
    &:hover {
      &.clickable {
        color: darken($c-font-2, 15);
      }
    }
  }

  &.light-gray {
    color: $c-font-3;
    &:hover {
      &.clickable {
        color: darken($c-font-3, 15);
      }
    }
  }
}
