.submit-safety-incident-form {
    .date-picker-form-field {
        width: 200px;
    }

    .textarea-form-field {
        textarea {
            height: 150px;
        }
    }

    .city-state-zip {
        width: 350px;
        
        .state {
            width: 250px;
        }
    }

    .number-of-days {
        width: 150px;
    }
}