.csv-import-review {
  @media print {
    font-size: 8.5px;
    line-height: 11px;
    height: 100%;

    .dialog-buttons {
      display: none !important;
    }

    .table-inner-wrapper {
      min-width: unset !important;
    }

    .table-cell {
      max-height: 250px !important;
      padding: 3px 0px !important;
      white-space: unset !important;
      text-overflow: unset !important;
      word-wrap: break-word;
      page-break-inside: avoid;
    }

    .table-container {
      padding-top: 0;
      overflow-y: auto;
      margin: 0 !important;
      overflow: auto;
    }

    .success-icon {
      width: 24px !important;
      height: 24px !important;
      padding: 5px !important;
    }

    .table-header-spacer {
      display: none;
    }

    .table-wrapper .table-inner-wrapper .table-header.sticky,
    .table-wrapper .table-inner-wrapper .table-header {
      display: none;
      position: fixed !important;
      top: 0 !important;
      width: 100% !important;
      border-bottom: solid 1px #ccc;
      box-shadow: none;
    }

    table {
      width: 100% !important;
    }

    h2 {
      padding-top: 20px;
    }
  }
}
