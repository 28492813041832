.activity-report-graph-header {
  padding: 36px 56px;
  height: 150px;
  border-radius: 3px;
  background-color: #3bcde2;
  color: white;
  line-height: 1em;
  position: relative;

  .row {
    height: 100%;
    align-items: center;
  }

  .more-button {
    position: absolute;
    margin-top: 10px;
    margin-right: 10px;
    top: 0;
    right: 0;
  }

  .activity-report-text-content {
    display: flex;
    flex-direction: column;

    .activity-report-graph-header-label {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.6em;
      line-height: 1em;
      margin-bottom: 8px;
    }

    .activity-report-graph-header-total {
      font-size: 2.5em;
      line-height: 1em;
    }
  }
}
