@import 'styles/theme';

.premium-trial-card {
  height: 100%;
  padding-bottom: 48px;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 5px solid rgb(52, 149, 253);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  .dashboard-title-card {
    margin: 12px 0 24px;
    color: $c-font-light-1;
  }

  .subtitle {
    text-align: center;
    width: 80%;
    color: $c-font-light-1;
    margin-bottom: 48px;

    .link {
      color: $c-primary;
      font-size: 16px;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
