@import 'styles/theme.scss';

.cost-code-activity-report-details {
  width: 100%;

  .cost-code-activity-report-details-table {
    border: solid 1px $c-stroke-10 !important;
    .cost-code {
      font-size: 16px;
      font-weight: 200;
    }
    .cost-code-title {
      font-size: 12px;
      opacity: 0.8;
    }

    @media print {
      .cost-code {
        font-size: 12px;
      }
      .cost-code-title {
        font-size: 9px;
      }
    }
  }
}
