@import './../../../styles/theme.scss';

.panel {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  max-height: 100%;
  min-height: 100%;
  transition: 150ms flex, 150ms border-color;
  overflow-x: hidden;
  overflow-y: auto;

  // Sizing
  &.full-height {
    height: 100%;
    max-height: 100%;
  }

  &.panel-small {
    flex-basis: 240px;
  }

  &.panel-medium {
    flex-basis: 300px;
  }

  &.panel-large {
    flex-basis: 400px;
  }

  &.panel-closed {
    flex-basis: 0 !important;
    border-color: transparent !important;
  }

  &.panel-small,
  &.panel-medium,
  &.panel-large,
  &.panel-closed {
    flex-grow: 0;
  }

  // Strokes
  &.panel-stroke-left {
    border-left: solid 1px $c-stroke-10;
  }

  &.panel-stroke-right {
    border-right: solid 1px $c-stroke-10;
  }
}
