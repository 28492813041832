.manage-equipment-map-info-window {
  white-space: pre-line;
  .row-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .row-disclosure-indicator {
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: space-between;
    float: right;
    margin-left: auto;
    margin-right: 0;
  }

  .btn-label {
    overflow: unset;
  }
}
