@import '../../../../../../components/foundation/dashboard/styles/dashboard-styles.scss';

.time-log-feed-dashboard-card-list {
  .list-item {
    flex-basis: 20%;
  }

  .row {
    flex-wrap: nowrap;
    height: 100%;
  }

  .time-log-feed-left-content {
    flex-basis: 80%;
  }

  .time-log-feed-value {
    flex-shrink: 0;
  }

  &.dark {
    .time-log-feed-label,
    .time-log-feed-value {
      color: #ffffff;
    }

    .time-log-feed-sublabel {
      color: $dashboard-dark-text-header;
    }
  }

  &.light {
    .dashboard-list-item-label {
      color: $dashboard-light-text-header;
    }

    .dashboard-list-item-value,
    .time-log-feed-sublabel {
      color: $dashboard-light-text-header;
    }
  }
}
