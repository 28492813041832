@import 'styles/theme.scss';

.create-equipment-form {
  .year-field {
    width: 180px;
  }
  .hour-meter-field {
    width: 180px;
  }
  .bottom-link-message {
    color: $c-font-2;
    opacity: 0.8;
    font-size: 16px;
  }
}
