@import 'styles/theme.scss';

.picker-row {
  >.row {
    flex-wrap: nowrap;
  }

  &.focused {
    .bar {
      background-color: $c-primary;
      color: #fff;
    }
  }

  & .highlight {
    font-weight: 700;
  }

  &:not(.focused) {
    .highlight {
      color: $c-primary;
    }
  }

  .btn {
    z-index: 1;
  }
}
