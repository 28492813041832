.timesheets {
  .table-body {
    .label {
      // Since the `Label` component has a 6px margin and a lot of table content is using `Label`
      // It's causing the text to be uncentered in the cell.  I don't anticipate this causing problems
      // and the `Label` itself probably should not have margin but it's all over the app.
      margin-bottom: 0px;
    }
  }
}
