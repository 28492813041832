.expanded-time-card-report {
  background-color: #f9f9f9;
  max-width: 1300px;
  min-width: 1300px;
  width: 1300px;
  .report-gap {
    margin-top: 100px !important;
  }
  margin: auto;

  .lazy-loader {
    overflow-y: hidden !important;
  }
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }

  .expanded-time-card-report {
    .report-gap {
      margin-top: 10px !important;
    }
    .px-6 {
      padding: 0px !important;
    }
    max-width: unset !important;
    min-width: unset !important;
    width: 100% !important;
    background-color: white;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    overflow: unset !important;

    div.time-card-page-break {
      display: block;
      break-after: always;
      page-break-after: always;
    }
    .page-break.timecard-report-container {
      position: relative !important;
      display: block;
      float: none !important;
      clear: both !important;
      @-moz-document url-prefix() {
        min-height: calc(100vh + 15mm);
      }
    }
    .page-break.timecard-report-container:first-child {
      @-moz-document url-prefix() {
        min-height: calc(100vh - 14mm);
      }
    }
    .signatures-footer {
      border: none;
    }

    .table-inner-wrapper > div {
      height: 20px !important; // for footer spacer
    }
    .signatures-footer label {
      font-size: 8px;
      line-height: 1.5;
    }
  }
}
