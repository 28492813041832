@import 'styles/theme.scss';

.time-range-dialog {
  .time-range-dialog-content {
    background-color: #f9f9f9;
  }

  .dialog {
    display: flex;
  }

  .panel-content {
    align-items: center;

    .middle-row {
      flex: 1;
      display: -webkit-box;
      height: 100%;
      overflow: unset;
    }

    .report-container {
      width: 100%;
    }
  }
}

@media print {
  .time-range-dialog {
    .time-range-report-content {
      background-color: white;
    }
  }
}
