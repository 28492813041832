@import 'styles/theme.scss';

.time-entry-report {
  background-color: #f9f9f9;
}

.empty-state {
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
}

.btn-icon {
  fill: RGB(190, 199, 208);
}

.bar-actions {
  display: flex;
}

.lock {
  display: flex;
  justify-content: space-between;
}
