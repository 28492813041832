@import '../styles/dashboard-styles.scss';
@import 'styles/theme.scss';

.dashboard-single-ribbon {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-content: stretch;

  > * {
    text-align: center;
  }

  .single-ribbon-value {
    font-size: 4em;
    line-height: 1em;
  }

  .single-ribbon-title {
    font-size: 1.2em;
    text-transform: uppercase;
  }

  .single-ribbon-subtitle {
    font-size: 0.8em;
    text-transform: uppercase;
  }

  &.dark {
    .single-ribbon-title {
      color: $dashboard-dark-text-header;
    }
    .single-ribbon-subtitle {
      color: $dashboard-dark-text-subheader;
    }

    .single-ribbon-value {
      color: $dashboard-dark-text-header;
    }


    &.is-in-iframe {
      background-color: $c-align-dark-background;
    }
  }

  &.light {
    .single-ribbon-title {
      color: $dashboard-light-text-header;
    }
    .single-ribbon-subtitle {
      color: $dashboard-light-text-subheader;
    }

    .single-ribbon-value {
      color: $dashboard-light-text-header;
    }
  }
}
