.split-time-entry-form {
  padding: 10px;

  .split-time-selectors {
    display: flex;
    & .split-time-time-field {
      flex: 1;
      max-width: 130px;
    }

    & .split-time-date-picker {
      flex: 1;
      max-width: 200px;
    }
  }

  .total-time-picker-item {
    display: inline-block;
  }
  .new-entry-range {
    display: inline-block;
    font-size: 12px;
    margin: 0 10px;
    & > div {
      display: inline-block;
    }
  }
  .break-disclaimer {
    font-size: 14px;
  }
}
