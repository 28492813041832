.photo-comment-view {
  margin: 10px 0;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: default;
  }
  .comment-header-section {
    display: flex;
    justify-content: space-between;
    .comment-header-text {
      flex-grow: 1;
    }
    .comment-time {
      color: #ccc;
      font-size: 12px;
    }
  }
  .comment-body {
    color: #46515a;
    font-size: 14px;
    margin-left: 60px;
    white-space: pre-line;

    .comment-edited {
      color: #ccc;
      font-size: 12px;
    }
  }
}
