.budget-dashboard-card {
  .progress-bar {
    background-color: #757984;
  }
  .project-name {
    color: #fff;
    font-weight: 500;
    text-transform: none;
  }
  .budget-dashboard-summary {
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.03);
    }
    .budget-dashboard-card-section {
      display: flex;
      flex-direction: row;

      .dashboard-card-bar {
        flex-grow: 15;
        min-height: 10px;
      }
      .dashboard-card-label {
        margin-left: 10px;
        font-size: 14px;
        max-width: 70px;
        min-width: 70px;
        text-align: right;
        color: #fff;
        flex-grow: 1;
        max-height: 18px;
        line-height: 18px;
      }
    }
  }

  .no-data-label {
    color: #fff;
    text-align: center;
  }
}
