@import 'styles/theme.scss';

.progress-bar {
  height: 10px;
  width: 100%;
  background-color: #dddddd;
  border-radius: 5px;
  .filler-bar {
    height: 100%;
    border-radius: inherit;
    font-weight: bold;
    text-align: right;
  }
}
