.preferences {
  .row {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    > * {
      flex-basis: 48%;
      height: 100%;
    }
  }
}

.preferences-name-format-dialog-form {
  margin: 60px 30px 30px;
}
