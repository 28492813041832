.timezone-form-field {
  .timezone-toggle {
    margin-left: 280px;
    position: relative;
    top: -54px;
    &.has-conflict {
      top: -108px;
    }
  }
}
