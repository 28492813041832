@import '@busybusy/webapp-react-ui/dist/styles/index.scss';

.signature-settings-dialog {
  .signature {
    border: 1px #dedede solid;
    height: 150px;
    padding: 10px;

    & > canvas {
      height: 135px;
    }
  }

  .warning {
    border: 2px solid rgba($c-danger, 0.5);
  }

  .warning-text {
    color: $c-danger;
    color: rgba($c-danger, 0.6);
    opacity: 1; /* Firefox */
  }
}
