.daily-project-report-card {
  padding: 0%;
  min-height: 250px;

  .report-item {
    display: flex;
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  .report-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 12px;
    .pdf-icon {
      width: 50px;
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      margin-left: 12px;
      & > img {
        width: 100%;
      }
      &.icon {
        width: 100%;
      }
    }
  }
  .report-information-container {
    width: 100%;
    margin-left: 16px;
    margin-right: 30px;
    margin: 12px;
  }
  .report-information-item-title {
    display: block;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
  }
  .report-information-item {
    display: block;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }

  &.dark {
    .report-information-item-title,
    .report-information-item {
      color: #e6e9eb;
    }
  }
  &.light {
    .report-information-item-title,
    .report-information-item {
      color: #4b5763;
    }
  }

  .empty-state {
    padding-top: 100px;
  }

  .row-disclosure-indicator {
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: space-between;
    float: right;
    margin-left: auto;
    margin-right: 12px;
  }
}

.daily-reports-card-title {
  font-size: 18px;
  font-weight: 700;
  &.dark {
    color: #e6e9eb;
  }
  &.light {
    color: #4b5763;
  }
}
