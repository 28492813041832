.customer-support-detail {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-content: center;

  .icon {
    flex: none;
  }

  .header-text {
    padding-right: 12px;
    padding-left: 12px;
    align-self: center;
  }
}
