@import 'styles/theme.scss';

.details-item {
  &:last-of-type {
    border-bottom: 0;
  }

  .details-item-title {
    margin-bottom: 1px;
    color: $c-font-2;
    font-size: 15px;
  }

  .details-item-value {
    font-size: 16px;
    margin-right: 20px;
  }
}
