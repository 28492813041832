@import './../../styles/theme.scss';

.app {
  display: flex;
  height: 100%;
}

/*
  Bad solution
  Somewhat time sensitive the real solution is to create a theme provider that can be used to override
  the ui library and we override the primary color in this case.  This only affects primary buttons currently.

  The reason this is outside the `App` class is because dialogs are rendered via a portal and are not children of
  the `App` class. 
*/
.is-in-iframe {
  .btn-primary {
    background-color: $c-align-primary !important;
  }
}

