@import 'styles/theme.scss';

.activity-report-summary {
  .detailTotalRowBackground {
    background: #ebf5ff;

    .table-row {
      background: #ebf5ff;
    }

    &:hover {
      background: #d6e1eb;
    }
  }
  .detailRowBackground {
    background: white;

    .table-row {
      background: white;
    }

    &:hover {
      background: #e0e3e6;
    }
  }

  .equipment {
    font-size: 16px;
    font-weight: 200;
  }
  .equipment-make-model {
    font-size: 12px;
    opacity: 0.8;
  }

  .cost-code {
    font-size: 16px;
    font-weight: 200;
  }
  .cost-code-title {
    font-size: 12px;
    line-height: 16px;
    opacity: 0.8;
  }

  @media print {
    padding-top: 0;
    font-size: 9px;
    line-height: 1;

    @page {
      margin-top: 0;
      margin-bottom: 0;
    }

    .table-inner-wrapper {
      overflow: hidden;
    }

    .equipment {
      font-size: 12px;
    }
    .equipment-make-model {
      font-size: 9px;
    }

    .cost-code {
      font-size: 12px;
    }
    .cost-code-title {
      font-size: 9px;
      line-height: 12px;
    }

    .cell-title {
      font-size: 12px;
      font-weight: 200;
    }
    .printer-head {
      border-bottom: solid 1px $c-stroke-10 !important;
    }
    .activity-print-header {
      margin-bottom: 0px !important;
    }
    .table-cell {
      text-align: left !important;
      vertical-align: middle !important;
      width: auto !important;
      padding: 5px !important;
      label {
        font-size: 9px;
        line-height: 1.5;
      }
    }
    .label {
      margin-bottom: 0px !important;
    }
    .name-column {
      vertical-align: middle !important;
      width: 200px !important;
    }
    .project-name-column {
      vertical-align: middle !important;
      width: 200px !important;
    }
    .table-footer {
      border-top: none !important;
    }
    .table-inner-wrapper > div:last-child {
      height: 24px !important; // for footer spacer
    }
  }
}
