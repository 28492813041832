.infoWindow {
  min-width: 300px;
  padding: 0px;
}

.gm-style-iw-d {
  padding: 0px;
  overflow: hidden !important;
}

.gm-style .gm-style-iw {
  padding: 0px;
  background-color: white !important;
}

.infoWindowTitle {
  font-weight: 500;
  float: left;
  color: #4b5764;
  font-size: 15px;
}

.location-distance {
  float: right;
  &.unauthorized {
    color: red;
  }
}

.infoWindowBody {
  line-height: 0.5em;
}
