@import '@busybusy/webapp-react-ui/dist/styles/index.scss';

.section {
  padding: $spacing-5 $spacing-5 0 $spacing-6;
}

.bottom-section {
  padding: $spacing-6;
}
.detail-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: $spacing-0;
}
.detail {
  font-weight: 400;
  font-size: 14px;
  color: #64696d;
  margin: 0px;
  padding-bottom: $spacing-6;
}
