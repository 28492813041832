@import 'styles/theme.scss';

.premium-features-container {
  background-color: white;
}

.feature-text {
  text-align: center;
}

.schedule-button {
  width: 220px;
  border-color: $c-primary;
  border-width: 2px;
}

.go-premium-button {
  width: 220px;
}

.feature-container {
  width: 100px;
  padding: 10px;
  margin: 15px;
  height: 110px;
}

.feature-heading {
  padding-top: 10px;
  text-transform: uppercase;
  font-size: 10px;
  text-align: center;
  line-height: 1.2em;
  font-weight: 500;
}

.separator {
  width: 80px;
  height: 4px;
  background-color: $c-primary;
}
