@import 'styles/theme.scss';

.location-history-dialog {
  height: 100%;

  .dialog {
    display: flex;
  }

  .back-arrow {
    align-self: flex-start;
    margin-top: 10px;
  }

  .header-title {
    font-size: x-large;
  }

  .header-subtitle {
    margin-top: 0;
    margin-bottom: 0;
  }

  .google-map {
    flex-grow: 1;
  }

  .location-outside-project-bar {
    color: #fff;
    background-color: $c-dark;
    display: flex;
    justify-content: space-between;
    & .message-content {
      display: flex;
      & .tooltip-icon {
        fill: #fff;
      }
    }
    .button-container {
      .button {
        border: 1px solid #fff;
        & .btn-label {
          color: #fff;
        }
      }
    }
  }

  .location-bar {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    &.unauthorized {
      color: $c-danger;
      background-color: rgba($color: $c-danger, $alpha: 0.07);

      & > svg {
        fill: $c-danger;
      }
    }
    &.authorized {
      color: $c-primary;
      background-color: rgba($color: $c-primary, $alpha: 0.07);

      & > svg {
        fill: $c-primary;
      }
    }
    &.no-longer-flagged {
      cursor: default;
      color: #fff;
      background-color: $c-dark;
    }
  }
}

.gps-disabled-dialog {
  .disabled-gps-alert-message {
    white-space: pre-line !important;
  }
}

.location-outside-project-dialog {
  .location-dialog-content {
    .label {
      color: #000;
    }
    .text {
      font-size: 14px;
    }
  }
}
