@import 'styles/theme.scss';

.daily-sign-off-form {
  .section-primary {
    color: $c-font-1;
    font-size: 16px;
    line-height: 18px;
    white-space: pre-line;
  }
  .section-secondary {
    color: $c-font-2;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    white-space: pre-line;
  }
  .section-options {
    color: $c-font-2;
    font-size: 16px;
    font-weight: 600;
  }
  .section-link {
    font-size: 16px;
    font-weight: 600;
  }
}
