.state-template {
  width: 100%;
  padding-top: 8%;
  text-align: center;

  > div {
    width: 50%;
    min-width: 180px;
    max-width: 320px;
    margin: auto;
  }
}
