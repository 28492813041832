.locked-account-banner {
  border-radius: 6px;
  background-color: #8c3e40;
  color: #fff;
  font-size: 12px;

  .contact-link {
    font-size: 12px;
    color: #fff !important;
    text-decoration: underline;
    cursor: pointer;
  }

  .lock {
    height: 70px;
    width: 70px;
    fill: #fff;
  }

  .label {
    color: #fff;
    font-size: 14px;
  }
}
