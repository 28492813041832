@import 'styles/theme.scss';

.thumbnail {
  position: relative;
  background-color: #eee;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  .thumbnail-dock {
    position: absolute;
    right: 0;
    bottom: 0%;
    left: 0;
    transform: translateY(100%);
    transition: 150ms transform;
    background-color: rgba(0, 0, 0, 0.5);

    &:hover {
      cursor: default;
    }

    .btn {
      &:hover {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &:hover {
    .thumbnail-dock {
      transform: translateY(0);
    }
  }

  &.thumbnail-sm {
    width: 80px;
    max-width: 80px;
    height: 80px;
    max-height: 80px;
  }

  &.thumbnail-md {
    width: 140px;
    max-width: 140px;
    height: 140px;
    max-height: 140px;
  }

  &.thumbnail-clickable {
    transform: scale(1);
    transition: 150ms transform;

    &:hover {
      transform: scale(1);
      cursor: pointer;
    }
  }
}
