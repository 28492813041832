@import 'styles/theme.scss';

.form-accordion {
  margin-bottom: 20px;
  padding: 18px 12px;
  transition: background-color 250ms;
  border: solid 1px $c-stroke-10;
  border-radius: 5px;
  background-color: #f5f5f5;

  &:hover {
    background: #eee;
    cursor: pointer;
  }

  .form-accordion-sign {
    margin-right: 8px;
    font-size: 20px;
    font-weight: bold;
  }

  .btn {
    width: 44px;
  }

  .form-accordion-title {
    margin-left: 8px;
    font-size: 15px;
  }

  & + .form-accordion-content {
    display: none;
  }

  & .icon {
    display: inline;
    margin-right: 30px;
    float: left;
  }
  &.open {
    background-color: $c-primary;
    color: #fff !important;

    & .icon * {
      fill: #fff !important;
    }

    & + .form-accordion-content {
      display: block;
    }
  }

  &.error {
    background: $c-danger;
    color: #fff;
  }

  &:focus {
    border: solid 1px $c-primary;
    outline: none;
    box-shadow: $c-shadow-field-focus;
  }
}
