.time-range-dialog-header {
  background-color: #fff;

  .header-title {
    font-size: large;
    &.label {
      margin-bottom: 0px;
    }
  }
}
