@import '../../styles/dashboard-styles.scss';

.dashboard-list-item {
  .row {
    flex-wrap: nowrap;
    height: 100%;
  }

  &:not(.clickable) {
    pointer-events: none;
  }

  .dashboard-list-item-value {
    font-weight: bold;
  }

  &.dark {
    .dashboard-list-item-label {
      color: #ffffff;
    }

    .dashboard-list-item-value {
      color: $dashboard-dark-text-header;
    }
  }

  &.light {
    .dashboard-list-item-label {
      color: $dashboard-light-text-header;
    }

    .dashboard-list-item-value {
      color: $dashboard-light-text-header;
    }
  }
}
