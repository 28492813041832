@media print {
  .no-print {
    display: none !important;
  }

  .print {
    display: block !important;
  }

  .printer-head {
    display: table-header-group !important;
  }

  .dialog-shell {
    position: absolute !important;
    bottom: auto !important;
    height: auto !important;
    box-shadow: none !important;
  }

  .dialog {
    box-shadow: none !important;
  }

  .body,
  .html,
  .root,
  .app,
  .main {
    height: auto !important;
  }

  .panel,
  .panel-content {
    overflow-y: auto;
  }

  .table-loader {
    padding-top: 0;
    font-size: 9px;
    line-height: 1;
  }

  .table-wrapper {
    .table-cell {
      white-space: normal;
      word-wrap: break-word;
      overflow-x: visible;
    }

    .table-inner-wrapper {
      min-width: 700px !important;
    }

    .table-wrapper .table-inner-wrapper .table-header.sticky,
    .table-wrapper .table-inner-wrapper .table-header {
      display: none;
      position: fixed !important;
      top: 0 !important;
      width: 100% !important;
      border-bottom: solid 1px #ccc;
      box-shadow: none;
    }

    .table-header {
      display: none !important;
    }

    .table-footer {
      height: 16px !important;
      overflow-x: unset !important;
    }

    table {
      width: 100% !important;

      &.table-body {
        break-inside: auto;

        .table-row {
          break-inside: avoid;
          break-before: auto;
          break-after: auto;
        }
      }
    }

    .table-header-spacer {
      display: none;
    }
  }

  .printed-table {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;

    th,
    td,
    tr,
    thead,
    tr {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
    }

    tr {
      font-size: 10px !important;
    }
  }
}

.print {
  display: none;
}

.printer-head {
  display: none;
}
