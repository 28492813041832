.simple-map-dialog {
  .dialog {
    display: flex;
    flex-direction: column;
    min-height: 85%;
  }

  .google-map {
    flex-grow: 1;
  }
}
