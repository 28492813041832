@import 'styles/theme.scss';

.hour-meter-report-table {
  .bar {
    border: 1px solid #e9e9e9;
    background-color: white;
  }

  .empty-hour-state {
    min-height: 120px;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    background-color: white;
    color: $c-font-2;
  }
}
