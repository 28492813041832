@import '../styles/dashboard-styles.scss';
@import 'styles/theme.scss';

.dashboard-enable-card {
  height: 100%;
  padding-bottom: 48px;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .clickable {
    cursor: pointer;
  }

  .dashboard-enable-title-card {
    margin: 12px 0 24px;
  }

  .learn-hide-text {
    margin-top: 10px;
    font-size: 0.9em;
  }

  &.dark {
    .dashboard-enable-title-card {
      color: $dashboard-dark-text-header;
    }

    .icon {
      fill: $dashboard-dark-text-header;
    }

    &.is-in-iframe {
      background-color: $c-align-dark-background;
    }
  }

  &.light {
    .dashboard-enable-title-card {
      color: $dashboard-light-text-header;
    }
    .icon {
      fill: $dashboard-light-text-header;
    }
  }

  .icon-container {
    min-width: 60px;
    max-width: 60px;
  }
}
