.expanded-time-cards-print-footer {
  background-color: white;
  page-break-inside: avoid;

  .section-title {
    font-size: 9px;
    font-weight: bold;
  }
  .section-info {
    line-height: 1.5;
    font-size: 9px;
    font-weight: regular;
  }
  .footer-logo {
    display: block;
    max-height: 12px;
    height: 12px;
    page-break-inside: avoid;
  }
}
