@import 'styles/theme.scss';

.details-item-wrapper {
  position: relative;
  padding: 18px;
  transition: background-color 200ms;
  border-bottom: solid 1px $c-stroke-10;

  &:last-of-type {
    border-bottom: none;
  }

  &.disabled {
    background: #fcfcfc;

    .details-item-arrow {
      display: none;
    }

    .details-item-value {
      color: $c-font-2;
    }

    &:hover {
      cursor: not-allowed;
    }
  }

  &.clickable {
    &:hover:not(.disabled) {
      background-color: #fcfcfc;
      cursor: pointer;
    }

    &:active:not(.disabled) {
      background-color: #eee;
    }
  }

  .details-item-info {
    margin-left: 10px;
    svg {
      fill: $c-font-3;
    }
  }

  .details-item-arrow {
    right: 15px;
    height: 24px;

    svg {
      fill: $c-font-3;
    }
  }

  .details-item-right {
    position: absolute;
    right: 78px;
    top: 50%;
    transform: translateY(-50%);
  }

  .details-item-arrow,
  .details-item-info {
    position: absolute;
    top: 50%;
    height: 24px;
    transform: translateY(-50%);
  }
}
