@import 'styles/theme.scss';

.header-resolution-text {
  float: left;
}

.accordion-icon {
  & svg > * {
    fill: #b6b6b7 !important;
  }
  .form-accordion.open .icon * {
    fill: #fff !important;
  }
}

.details-container {
  background-color: #f7f7f7;
  padding: 8px 0;
  margin-top: 20px;

  .details-card {
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #e4e4e4;
    margin: 16px;
    padding: 16px;

    .header-title {
      font-weight: bold;
    }

    &.details-card-error {
      border-color: $c-danger;
    }

    .answer-label {
      font-weight: bold;
      margin-top: 8px;
    }

    .notes {
      white-space: pre-line;
    }
  }

  .button-bar {
    display: flex;
    margin: $spacing-8 0 $spacing-2;

    .btn {
      width: 100%;
    }

    .btn + .btn {
      margin-left: $spacing-4;
    }
  }
}

.no-results-card {
  border-radius: 10px;
  border: 1px solid #0083fe;
  margin: 5px;
  padding: 16px;
  color: #0083fe;
  background: #eef5ff;

  .no-results-label {
    font-weight: bold;
    margin-top: 8px;
    color: #0083fe;
  }
}

.member-details-label {
  font-size: 12px;
  font-weight: 600;
  margin: -2px 0;
}
