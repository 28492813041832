@import 'styles/theme.scss';

.delete-button {
  border: none;
}

.total-time-picker-item {
  width: 100px;
}

.date-picker-item {
  width: 250px;
}

.start-date-warning {
  color: $c-danger;
}
