@import 'styles/theme.scss';

.dashboard {
  background-color: #162430;
  &.is-in-iframe {
    background-color: $c-align-darker-background;
  }

  overflow: auto;
  padding: 25px;
}
