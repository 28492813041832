$dashboard-dark-background: #2e3e51;
$dashboard-dark-text-header: #e6e9eb;
$dashboard-dark-text-subheader: #abb2b9;
$dashboard-dark-text: #828b97;

$dashboard-light-background: #fff;
$dashboard-light-text-header: #30373d;
$dashboard-light-text-subheader: #a7a7a8;
$dashboard-light-text: #a7a7a8;
$dashboard-light-border: 1px #e8e8e8 solid;
