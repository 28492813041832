@import 'styles/theme.scss';

.time-entry-data-report {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.btn-icon {
  fill: RGB(190, 199, 208);
}

.bar-actions {
  display: flex;
}

.lock {
  display: flex;
  justify-content: space-between;
}

@media print {
  .time-entry-data-report {
    background-color: white;
  }

  div.time-entry-page-break {
    display: block;
    break-after: always;
    page-break-after: always;
  }
  div.time-entry-page-break:last-child {
    break-after: avoid;
    page-break-after: avoid;
  }
  .page-break.time-entry-data-report {
    position: relative;
    @-moz-document url-prefix() {
      position: absolute;
    }
    display: block;
    float: none !important;
    clear: both;
  }
  div {
    overflow: unset !important;
    float: none !important;
  }

  * {
    float: none !important;
  }

  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  .time-entry-data-report {
    width: 100% !important;
    padding-top: 0;
    font-size: 9px;
    line-height: 1.5;
    overflow: unset !important;

    table {
      box-decoration-break: clone !important;
    }

    .table-wrapper {
      padding-bottom: 0px !important;
    }

    .table-cell {
      text-align: left !important;
      padding: 5px !important;
      label {
        font-size: 9px;
        line-height: 1.5;
      }
    }
    .menu-date {
      label {
        margin-left: 0 !important;
      }
    }
    .table-cell-checkbox {
      display: none !important;
    }
    .description-note {
      overflow-y: unset !important;
    }
  }
}
