.detail-header-with-text {
  display: block;
  .detail-header {
    display: block;
    font-size: 16px;
    font-weight: bold;
  }

  .value {
    font-size: 16px;
    white-space: pre-line;
  }
}
