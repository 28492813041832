.ac-fade-slide-up {
  animation-name: ac-fade-slide-up;
  animation-duration: 400ms;
  animation-timing-function: ease-out;
}

@keyframes ac-fade-slide-up {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.ac-fade-slide-down {
  animation-name: ac-fade-slide-down;
  animation-duration: 400ms;
  animation-timing-function: ease-out;
}

@keyframes ac-fade-slide-down {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.ac-fade-in-place {
  animation-name: ac-fade-in-place;
  animation-duration: 400ms;
  animation-timing-function: ease-out;
}

@keyframes ac-fade-in-place {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ac-pulse {
  animation-name: pulse;
  animation-duration: 750ms;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  @keyframes pulse {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.03);
    }
  }
}

.ac-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
}

.ac-fade-in {
  animation-name: ac-fade-in;
  animation-duration: 400ms;
  animation-timing-function: ease-out;
}

@keyframes ac-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ac-spin {
  animation: spinner 1s linear infinite;
}

.ac-spin-reverse {
  animation: spinner 1s linear infinite;
  animation-direction: reverse;
}

.ac-spin-faster {
  animation-duration: 500ms;
}

@keyframes ac-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
