.photo-full-screen {
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    height: calc(100vh - 200px);

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
