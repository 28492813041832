@import 'styles/theme.scss';

$line-offset: 22px;
$line-color: #ccc;

.project-leaf {
  display: flex;
  position: relative;
  align-items: stretch;

  .leaf-line {
    width: 1px;
    transform: translate(calc(10px - #{$line-offset}), -15px);
    background-color: $line-color;
  }

  &:last-child {
    > .leaf-line {
      background-color: transparent;
    }
  }

  .leaf-content {
    .project-leaf-row {
      display: flex;
      align-items: stretch;
      min-height: 36px;

      .column-line {
        width: 1px;
        transform: translate(calc(9px - #{$line-offset}), -17px);
        background-color: $line-color;
      }

      .row-content {
        display: flex;
        align-items: center;

        .row-title {
          margin-left: 36px;
          transition: 150ms color;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.selected {
            color: $c-primary;
          }

          &:hover {
            color: #000;
            cursor: pointer;
          }
        }

        .row-line {
          position: absolute;
          left: 0;
          width: 40px;
          height: 1px;
          transform: translate(calc(10px - #{$line-offset}), 0);
          background-color: $line-color;
        }

        .project-leaf-btn {
          width: 26px;
          height: 26px;
          padding: 0;
          z-index: 100;

          & + .row-title {
            margin-left: 10px;
          }
        }
      }
    }

    .project-leaf-children {
      margin-left: 24px;

      .children-line {
        transform: translate(-13px, -22px);
        border-left: solid 1px $line-color;
      }
    }
  }

  &.selected > .leaf-content > .project-leaf-row .row-title {
    color: $c-primary;

    &:hover {
      color: $c-primary;
    }
  }

  &.archived > .leaf-content > .project-leaf-row .row-title {
    font-style: italic;
  }

  &.archived:not(.selected) > .leaf-content > .project-leaf-row .row-title {
    color: $c-font-2;
  }
}
