@import 'styles/theme.scss';

.tab-bar {
  .tab-count-label {
    text-align: center;
    margin-bottom: 0px !important;
  }

  .tab-container,
  .tab-label {
    text-align: center;

    &.disabled:hover {
      cursor: not-allowed;
      .tab-container,
      .tab-label,
      .tab-count-label {
        cursor: not-allowed;
      }
    }
  }

  &.tabs {
    overflow: hidden;
    height: 64px;
  }

  .btn {
    &.tab-active {
      .tab-count-label,
      .tab-label {
        color: $c-primary;
      }
    }

    &:focus:not(.disabled) {
      animation: none;
    }
  }

  .btn-label {
    height: 100%;
  }

  .selection-label {
    color: white;
  }
}
