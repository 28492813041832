@import 'styles/theme.scss';

.time-card-ready-to-sign-well {
  .well-contents {
    display: flex;
    .right-contents {
      display: flex;
      text-align: right;
      justify-content: space-between;
      float: right;
      margin-left: auto;
      margin-right: 0;
    }
    .title-label {
      color: $c-primary;
    }
  }
}
