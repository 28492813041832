.customer-support-action-list {
  .chat-support-detail {
    padding: 20px;
  }

  .details-card {
    margin-bottom: 16px;
  }

  .icon {
    fill: #000;
  }
}
