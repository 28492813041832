.lazy-load-check-list {
  overflow-y: auto;

  .lazy-load-check-list-item-text {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .concealed-section {
    z-index: 0;
  }

  .lazy-loader {
    z-index: 1;
  }

  .list-item {
    background-color: white;
  }
}
