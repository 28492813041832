@import './../../../../styles/theme.scss';

$item-width: calc(100% - 16px);

.navigation {
  max-width: 260px;
  min-width: 260px;
  background-color: #2e3e51;

  &.is-in-iframe {
    min-width: 265px;
    max-width: 265px;

    background-color: $c-align-dark-background;

    .nav-item {
      &.active {
        background-color: $c-align-primary;
      }
    }
  }

  color: rgba(255, 255, 255, 0.6);
  fill: rgba(255, 255, 255, 0.6);

  .cls-1 {
    fill: #caccd0;
  }

  // background-image: linear-gradient(#463e52, #2d3e50); //#3a3e50,
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  z-index: 901;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  .side-nav-menu-button {
    margin-left: 16px;
    background: #697480;

    &:hover {
      background-color: #596574;
    }
  }

  .btn-label {
    color: rgba(255, 255, 255, 0.6);
  }

  h3 {
    align-self: flex-start;
    margin-left: 24px;
  }

  .icon {
    fill: #caccd0;
  }

  .person-icon {
    background: #697480;
    border-radius: 50%;
  }

  .divider {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .nav-accordion {
    &.accordion-shell {
      display: flex;
      flex-direction: column;
      align-content: center;
      width: $item-width;

      .accordion-content {
        .nav-item {
          width: 100%;
        }
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        padding: 4px 0;
      }
    }
  }

  .nav-item {
    margin-top: 4px;

    &:hover {
      cursor: pointer;
    }

    &.active-parent {
      background-color: #425062;
    }

    display: flex;
    position: relative;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    width: $item-width;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.8);

    .icon {
      position: absolute;
      left: 20px;
    }

    .expand-icon {
      position: unset;
      margin-right: 8px;
    }

    &:hover:not(.active) {
      background-color: rgba(0, 0, 0, 0.15);
    }

    .nav-item-label {
      margin-left: 54px;
    }

    &.active {
      background-color: darken($c-primary, 3);

      .icon {
        fill: rgba(255, 255, 255, 0.8);
      }

      .btn-label {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  .button-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    .divider {
      margin: 8px 0;
    }
  }
}

.navigation::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
