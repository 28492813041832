.quick-time-template-list {
  display: flex;
  align-items: center;
  .template-row {
    padding: 8px;
    flex-grow: 1;
  }

  .quick-time-draggable-list {
    margin-top: 32px;
    width: 50%;
  }
}
