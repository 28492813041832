.expanded-time-cards-summary-table {
  padding: 5px 20px 20px 20px;
  background-color: white;
  .table-body {
    .table-row {
      .table-cell {
        vertical-align: top;
        border-left-width: 0;
      }
    }
  }
  .table-header {
    .table-row {
      height: 40px;
    }
  }
  .table-view-header-no-border {
    border-left: none !important;
    border-bottom: none !important;
    font-size: 12px;
    font-weight: bold;
    background-color: #dee8ec;
    height: 40px;
  }
  .table-view-cell-no-border {
    border: 0px !important;
    border-bottom: 1px solid #dee8ec !important;
    padding: 0 !important;
    margin: 0 !important;
    border-collapse: collapse;
    font-size: 12px;
    font-weight: regular;
  }
  .table-header {
    border-bottom: none !important;
  }
  .table-footer {
    border-top: none !important;
    .table-cell {
      border: 0px !important;
      font-weight: bold;
    }
  }
  .unassigned-title {
    font-style: italic;
  }
}
