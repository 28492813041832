@import 'styles/theme.scss';

.time-entry-data-table-header {
  .bar {
    border: 1px solid #e9e9e9;
    background-color: white;
  }

  .label {
    font-size: 22px;
  }

  .time-entry-data-table-header-title {
    margin-bottom: 0px;
  }

  .bar-actions {
    display: flex;
    align-items: center;

    .label {
      margin-bottom: 0px;
    }
  }
}

@media print {
  .time-entry-data-table-header {
    break-inside: avoid;

    .bar {
      height: 24px !important;
      border: none;
      border-bottom: 2px solid $c-primary;
      min-height: 24px !important;
    }

    label {
      padding: 0px !important;
      margin: 0px !important;
      font-size: 10px !important;
      font-weight: bold;
    }

    .row {
      padding: 0px !important;
    }
  }

  .time-entry-data-table-header:not(:first-child) {
    margin-top: 20px;
    box-decoration-break: clone !important;
  }
}
