.profile-image-form-field {
  .profile-image-preview {
    width: 100%;
    background-color: #fafafa;

    .profile-image-container {
      width: 200px;
      height: 200px;
      background-position: center;
      background-size: cover;
    }
  }
}
