@import 'styles/theme.scss';

.well {
  padding: $spacing-5;
  border-radius: 5px;

  &.well-primary {
    border: 1px solid $c-primary;
    background-color: lighten(rgba($c-primary, 0.2), 10%);
    color: $c-primary;
  }

  &.well-danger {
    border: 1px solid $c-danger;
    background-color: lighten(rgba($c-danger, 0.2), 10%);
    color: $c-danger;
  }

  &.well-outline {
    border: 1px solid $c-gray-border;
  }
}
