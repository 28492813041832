@import 'styles/theme.scss';

.premium-trial-dialog {
  .header {
    text-align: center;

    .divider {
      width: 100px;
      margin-left: auto;
      margin-right: auto;
      border: solid 2px $c-primary;
    }
  }

  .pro-features {
    background-color: #fafafa;
  }

  .daily-reports {
    background-color: #fafafa;
  }

  .progress-tracking {
    background-color: #fafafa;
  }

  .premium-feature-marketing {
    .content-container {
      width: 100%;

      .image-container {
        margin-left: 100px;
      }

      .dual-panel {
        margin-left: 0px;

        .panel {
          width: fit-content;
          margin-right: 50px;
        }
      }
    }
  }
}

.video-fullscreen-viewer {
  .btn {
    &.btn-dark {
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .header {
    flex-shrink: 0;
    background-color: black;
    color: $c-font-light-1;

    .tray {
      margin-left: auto;
    }
  }

  .wistia-container {
    width: 100%;
    background-color: #1c1c1c;

    .wistia-video-embed {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
