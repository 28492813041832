@import '@busybusy/webapp-react-ui/dist/styles/index.scss';
@import url('https://fonts.googleapis.com/css?family=Allura');

.signature-container {
  width: 90%;
  max-width: 90% !important;
  margin: 10px;
  min-height: 150px;
  background-color: #fff;
}

.typed-signature-label {
  font-size: 12px;
}

.typed-signature-field > input {
  font-family: 'Allura';
  font-size: 24px;
}

.signature {
  border: 1px #dedede solid;
  height: 150px;
  padding: 10px;

  & > canvas {
    height: 135px;
  }
}

.warning {
  border: 2px solid rgba($c-danger, 0.5);
}

.warning-text {
  color: $c-danger;
  color: rgba($c-danger, 0.6);
  opacity: 1; /* Firefox */
}
