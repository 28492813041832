.multi-picker {
  .row {
    overflow: visible;
  }

  .icon-button {
    justify-self: start;
    align-self: flex-end;
  }

  .more-text {
    color: #3598fe;
    font-weight: 600;
    margin: 8px 0px 0px 8px;

    &:hover {
      cursor: pointer;
    }
  }
}
