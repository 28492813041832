@import 'styles/theme.scss';

.account-settings {
  .account-settings-content {
    > * {
      flex-basis: 48%;
      min-width: 300px;
    }
  }
  .verify-email-container {
    .verify-email {
      border-bottom: 1px solid $c-primary;
    }
  }
}
