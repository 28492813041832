@import 'styles/theme.scss';

.column {
  width: 90%;
  max-width: 90% !important;
  margin: 10px;
  min-height: 150px;
  background-color: #fff;
  & .row {
    & .col {
      width: 50%;
      padding: 15px 0px;

      & .col-header {
        display: block;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
      }

      & .col-value {
        font-size: 16px;
        word-wrap: break-word;
      }
    }
  }
}

.entry-description {
  white-space: pre-line;
}
