@import 'styles/theme.scss';
@import '../styles/dashboard-styles.scss';

.dashboard-annoucement {
  width: 100%;
  border-radius: 6px;
  min-width: 1200px;

  .content-column {
    max-width: 700px;

    .tray {
      align-items: flex-start;
    }
  }

  .title {
    font-size: 24px;
    margin-left: 16px;
    font-weight: 600;
  }

  .content {
    white-space: pre-line;
    font-weight: 100;
    color: #ccc;
  }

  .content-title {
    font-size: 18px;
    font-weight: 600;
  }

  .content-title-link {
    color: $c-primary;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }

  .announcement-icon {
    width: 42px;
    height: 100%;
    margin-top: -8px;
  }

  .close-icon {
    float: right;
  }

  .btn-secondary {
    background-color: #2e3e51;
    border: 1px solid #e6e9eb;

    .btn-label {
      color: #e6e9eb;
    }
  }

  &.dark {
    background-color: $dashboard-dark-background;
    color: $dashboard-dark-text-header;
    .announcement-icon {
      fill: #ffbd01;
    }
    .close-icon {
      .icon {
        fill: $dashboard-dark-text-subheader;
      }
    }
    .thumbnail {
      background-color: $dashboard-dark-background;
    }
  }

  &.light {
    color: $dashboard-light-text-header;
    background-color: $dashboard-light-background;
    border: $dashboard-light-border;
    .announcement-icon {
      fill: #ffbd01;
    }
    .close-icon {
      .icon {
        fill: $dashboard-light-text-subheader;
      }
    }
    .thumbnail {
      background-color: $dashboard-light-background;
    }
  }

  .link {
    color: $c-primary;
    font-size: 16px;
    font-weight: 600;
  }

  .thumbnail {
    max-width: 340px;
    max-height: 340px;
    width: 340px;
    height: 340px;
  }

  .vertical-divider {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }
}
