@import 'styles/theme.scss';

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}

.no-text-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.flex-grow {
  flex-grow: 1;
}

.justify-content-space-between {
  justify-content: space-between;
}

.form-dialog-padding {
  padding: $spacing-6 $spacing-8 $spacing-6 $spacing-8;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.flex {
  display: flex;
}

.dialog-content {
  padding: $spacing-4 $spacing-8 $spacing-8 $spacing-8;
}

.hidden {
  display: none;
}

.highlight {
  color: $c-primary;
}

.report-background-color {
  background-color: #f9f9f9;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-unset {
  overflow-x: unset;
}

.overflow-y-unset {
  overflow-y: unset;
}

.text-wrap-wrap {
  text-wrap: wrap;
}

.embedded-table-border {
  border-top: 1px solid $c-stroke-10;
  border-left: 1px solid $c-stroke-10;
  border-right: 1px solid $c-stroke-10;
}

.list-style-none {
  list-style: none;
}
