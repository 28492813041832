@import '../styles/dashboard-styles.scss';

.dashboard-content-ribbon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px;
  align-items: center;
  align-content: stretch;

  .dashboard-ribbon-label-title {
    font-size: 1.3em;
    margin-bottom: 6px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .dashboard-ribbon-label-subtitle {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 600;
  }

  .dashboard-ribbon-label {
    flex-basis: 80%;
    display: flex;
    flex-direction: column;
  }

  .dashboard-ribbon-value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    flex-basis: 20%;
    margin-bottom: 6px;

    &:not(.subvalue) {
      font-size: 4em;
    }
    &.subvalue {
      font-size: 2em;
      font-weight: 700;
    }
  }

  .subvalue {
    text-align: right;
  }

  &.dark {
    .dashboard-ribbon-label-title {
      color: $dashboard-dark-text-header;
    }
    .dashboard-ribbon-label-subtitle {
      color: $dashboard-dark-text-subheader;
    }
    r .dashboard-ribbon-value {
      color: $dashboard-dark-text-header;
    }
  }

  &.light {
    .dashboard-ribbon-label-title {
      color: $dashboard-light-text-header;
    }
    .dashboard-ribbon-label-subtitle {
      color: $dashboard-light-text-subheader;
    }

    .dashboard-ribbon-value {
      color: $dashboard-light-text-header;
    }
  }
}
