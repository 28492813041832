@import '@busybusy/webapp-react-ui/dist/styles/index.scss';

.csv-import-overview-header {
  align-items: center;
  height: 80px;

  .checkbox {
    margin: 0 $spacing-4;
  }

  .btn {
    margin: 0 $spacing-5;
  }

  * {
    margin: 0;
  }
}
