.dashboard-grid-wrapper {
  .dashboard-grid {
    display: grid;
    padding: 25px 0;
    grid-gap: 25px;
    grid-template-columns: repeat(3, 1fr);
  }

  .dashboard-grid-large {
    display: grid;
    padding: 25px 0;
    grid-gap: 25px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    * {
      grid-column: span 3;
    }

    .dashboard-clock-status {
      grid-column: span 4;
    }
  }
}
