.search-bar {
  width: 100%;
}

.search-bar {
  > .icon-container {
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 24px;

    &:first-child {
      left: 12px;
    }

    &:last-child {
      right: 12px;
    }

    > .icon {
      position: unset;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
    }
  }
}
