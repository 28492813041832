.photo-comment-bar {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .comments-container {
    flex-grow: 1;
    overflow-y: auto;
  }

  .add-comment-section {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 1rem;
  }
  .avatar {
    .avatar-image {
      z-index: auto;
    }
  }
}
