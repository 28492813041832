.review-safety-incident-form {
  max-width: 1200px;
  min-width: 600px;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fill-available;

  .tray {
    width: 100%;

    .title-content {
      width: -webkit-fill-available;
    }
  }

  .review-safety-incident-form-content {
    width: 100%;
    display: flex;

    .left-content {
      width: 50%;
    }

    .right-content {
      width: 50%;
    }
  }

  .general {
    .btn {
      height: 24px;
      margin-top: 4px;
      padding: 0px;
    }
  }

  .employee-details {
    .recordable-info {
      font-size: 14px;
      line-height: normal;
    }

    .btn {
      width: 110px;
    }
  }
}
