.signatures-footer {
  display: flex;
  min-height: 100px;
  border: 1px solid #e9e9e9;
  background-color: white;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 16px;

  .employee-signature-layout {
    display: flex;
    flex: 1;
    margin-left: 16px;
    margin-right: 32px;
    border-bottom: 1px solid #e9e9e9;
    justify-content: flex-start;
    align-items: baseline;
    max-width: 600px;
  }

  .supervisor-signature-layout {
    display: flex;
    flex: 1;
    margin-left: 32px;
    margin-right: 16px;
    border-bottom: 1px solid #e9e9e9;
    justify-content: flex-start;
    align-items: baseline;
    max-width: 600px;
  }

  .signature-with-date-layout {
    width: 480px;
    margin-top: 16px;
    margin-left: 16px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    min-width: 480px;
  }

  .date-layout {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  .signature-image {
    width: 400px;
    max-height: 100px;
    min-width: 400px;
  }
}

@media print {
  .signature-with-date-layout {
    width: 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;
  }
  .signature-image {
    width: 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;
    max-height: 48px !important;
  }
  .signatures-footer {
    label {
      font-size: 12px;
    }
  }
}
