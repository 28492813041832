.media-entry {
  max-width: 140px;

  .watermark {
    max-height: 139px;
    &:hover {
      cursor: pointer;
    }
    .watermark-text {
      display: block;
      position: relative;
      color: #fff;
      float: right;
      bottom: 140px;
      text-align: center;
      justify-content: center;
      font-weight: 700;
      z-index: 2;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.7);
      height: 30px;
      font-size: 12px;
      padding: 3px;
      .watermark-text-content {
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    .watermark-timestamp {
      display: block;
      position: relative;
      color: #fff;
      left: 89px;
      bottom: 30px;
      text-align: center;
      justify-content: center;
      font-weight: 700;
      z-index: 1;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.7);
      height: 30px;
      width: 50px;
      font-size: 12px;
      padding: 3px;
      &.with-comments {
        left: 90px;
      }
      .watermark-timestamp-content {
        justify-content: space-between;
        align-items: flex-start;
      }
    }
  }

  .checkbox {
    width: 35px;
    height: 35px;

    input:checked ~ .checkmark {
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.8);
    }
  }

  &:not(:hover) {
    .checkbox {
      input:not(:checked) ~ .checkmark {
        display: none;
        transform: scale(0);
      }
    }
  }
}
