@import 'styles/theme.scss';

.capsule {
  border-radius: 50px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px #e8e8e8 solid;
  > * {
    margin: 1px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  color: $c-font-1;
  font-weight: 500;
  font-size: 12px;
  min-width: 75px;
  .close-button {
    margin-left: 5px;
    height: 15px;
    width: 15px;
  }
}

.selectable {
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
  }
}
