@import 'styles/theme.scss';

.break-multi-picker {
  .break-item {
    display: flex;
    align-items: center;
    height: 44px;
    border: solid 1px $c-stroke-10;
    border-radius: 3px;

    .break-item-left-content {
      display: flex;
      justify-content: space-between;
      &:first-child {
        flex-grow: 2;
      }
    }

    &:hover {
      cursor: pointer;
    }

    & > * {
      padding-left: 10px;
      padding-right: 10px;
    }

    .btn {
      align-self: center;
      margin-right: 4px;
    }
  }
}

.input-error {
  border: solid 1px $c-stroke-field-error;
  color: $c-danger;
  background-color: $c-bg-field-error;

  &::placeholder {
    color: rgba($c-danger, 0.5);
    opacity: 1; /* Firefox */
  }
}
