@import '../../../../../../components/foundation/dashboard/styles/dashboard-styles.scss';
@import 'styles/theme.scss';

.progress-entries-dashboard-card-list {
  height: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;

  .list-item {
    flex-basis: 15%;
    &.dark {
      border-color: $dashboard-dark-text-subheader;
    }
    &.light {
      border-color: $dashboard-light-text-subheader;
    }
  }
  .dashboard-list-item {
    .row {
      flex-wrap: nowrap;
      height: 100%;
    }

    &:not(.clickable) {
      pointer-events: none;
    }

    &.dark {
      .dashboard-list-item-label {
        color: $dashboard-dark-text-subheader;
      }

      .dashboard-list-item-value {
        color: $dashboard-dark-text-header;
      }
    }

    &.light {
      .dashboard-list-item-label {
        color: $c-font-1;
      }

      .dashboard-list-item-value {
        color: $dashboard-light-text-header;
      }
    }
  }
}
