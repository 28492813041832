@import 'styles/theme.scss';

.feature-time-form-field {
  .time-zone-sup {
    font-size: 10px;
    color: $c-font-2;
    text-align: end;
    margin-right: 20px;
  }
}
