.dashboard-settings-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  .gears-icon {
    opacity: 0.8;
  }

  .checkbox-item {
    flex-grow: 1;
  }
}
