@import 'styles/theme.scss';

.error {
  color: $c-danger;
}

.display-text {
  display: inline-block;
}

.tab {
  display: inline-block;
  margin-right: 40px;
}
