@import 'styles/theme.scss';

.premium-feature-marketing {
  .content-container {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    padding-top: 60px;
    padding-bottom: 70px;
    display: flex;
    align-items: center;

    .image-container {
      display: inline-block;
    }

    .text-container {
      width: inherit;
      display: inline-block;

      h1 {
        text-align: left;
      }

      .button-container {
        display: flex;
        align-items: center;

        .btn {
          width: 48%;
          height: 50px;
          max-width: 200px;
        }

        .btn-secondary {
          .btn-label {
            color: $c-primary;
          }

          border: solid 1px $c-primary;
        }
      }

      .feature-item {
        list-style-type: none;
        display: flex;
        align-items: center;
      }
    }
  }

  .dual-panel {
    border: none;

    .panel {
      border: none;
    }
  }
}
