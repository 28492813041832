@import 'styles/theme.scss';

.labor-budget-form {
  .well {
    padding: 5px;
    padding-left: 20px;
  }
  .enable-switch-info {
    svg {
      fill: $c-font-3 !important;
    }
  }
}
