.employee-check-list {
  max-height: 100vh;

  .employee-check-list-content {
    min-height: 64px;
    overflow-y: auto;
  }

  .error-state,
  .empty-state {
    padding: 0;
    margin: 16px 0;
  }
}
