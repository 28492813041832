@import 'styles/theme.scss';

.picker {
  display: flex;
  align-items: center;
  height: 44px;
  border: solid 1px $c-stroke-10;
  border-radius: 3px;

  &:focus {
    &:not(.picker-disabled) {
      border: 1px solid rgba($c-primary, 0.8);
      outline: none;
      box-shadow: $c-shadow-field-focus;
    }
  }

  &.picker-error {
    border: solid 1px $c-stroke-field-error;
    background-color: $c-bg-field-error;
    color: $c-danger;
  }

  &.picker-disabled {
    outline: none;
    background: $c-bg-field-disabled;
    color: $c-font-disabled;

    &:hover {
      cursor: not-allowed;
    }
  }

  >.picker-title {
    flex: 1;
    padding-left: 12px;
  }

  .drop-icon {
    display: flex;
    justify-content: center;
    width: 40px;
  }
}
