.project-activity-export-options-dialog {
  .radio {
    height: unset;
    display: flex !important;

    .radio-checkmark {
      margin-top: 4px;
    }

    & + .radio {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .radio-label {
      width: 90%;
    }
  }
}
