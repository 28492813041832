@import 'styles/theme.scss';

.time-range {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  * > {
    flex-grow: 1;
  }

  .time-range-start-time {
    margin-right: $spacing-2;
    width: 100%;
  }

  .time-range-end-time {
    margin-left: $spacing-2;
    width: 100%;
  }

  .dash {
    display: flex;
    align-items: flex-end;
    margin-bottom: 12px;
  }
}
