@import '../../../components/foundation/dashboard/styles/dashboard-styles.scss';

$color: $dashboard-dark-text-subheader;

.dashboard-grid-header {
  color: $color;
  .tray {
    .icon {
      fill: $color;
    }
  }
}
